import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, MenuItem, Popover, Select, Typography } from '@mui/material';
import "./styles/FiltroEdad.css"

export default function TransitionsPopper(props) {
    // const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setOpen((previousOpen) => !previousOpen);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    let genderOptions=["Masculino", "Femenino", "Todos"]
    // const [gender, setgender] = React.useState(genderOptions[2]);

    const handleChange = (event, newValue, activeThumb) => {
      if (newValue.props.value===""){
        props.setgender(undefined);  
      }else{
        props.setgender(newValue.props.value);
      }
      handleClose();
      
        // return "";
      };

    function displayOption(value){
      if (value==="" || value===undefined){
        return "Todos"
      }
      if (value==="hombre"){
        return "Masculino"
      }
      if (value==="mujer"){
        return "Femenino"
      }
    }
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <div>
            {/* <span classname="filterbutton">Test</span> */}

            <Typography variant="body1" display="inline" sx={{color:"#ABA9AA"}}>Sexo asignado al nacer: </Typography>
            <Typography className="filterbutton" variant="body1" sx={{color:"#1976d2"}} display="inline" aria-describedby={id} onClick={handleClick}>
              {displayOption(props?.gender)}
            </Typography>
            
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                {/* {({ TransitionProps }) => ( */}
                    {/* <Fade {...TransitionProps} timeout={350}> */}
                        <Box sx={{ border: 1, p:4,width:"300px", height:"3em",bgcolor: 'background.paper'}}>
                          
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Sexo</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={props.gender}
                              label="Sexo"
                              defaultValue={""}
                              onChange={handleChange}
                            >
                              <MenuItem value={"hombre"}>{genderOptions[0]}</MenuItem>
                              <MenuItem value={"mujer"}>{genderOptions[1]}</MenuItem>
                              <MenuItem value={""}>{genderOptions[2]}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                    {/* </Fade> */}
                {/* )} */}
            </Popover>
        </div>
    );
}