import React from "react";
import { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import Home from "./Home.js"
import PatientDetailsPage from "./PatientDetailsPage"
import { Context } from "./hooks/context";


function RouterPage() {

  const {
    User,
  } = useContext(Context);

  function isLoggedIn(User){
    if(User===null){
        return true
    }
    return false
    }


return (<Router >
      
        <div >
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
            <Route path="/" element={isLoggedIn(User)? <LoginPage />:<Home />} />
            <Route path="/login" element={isLoggedIn(User)? <Navigate to="/" replace /> : <LoginPage />} />
            <Route path="/patient/:id" element={isLoggedIn(User)? <Navigate to="/login" replace />: <PatientDetailsPage />} />
            
        </Routes>
        </div>
        </Router>);

    }

    export default RouterPage;