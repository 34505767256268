import React from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { Box, Divider, Grid, Paper } from '@mui/material';
// import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


var enumerateDaysBetweenDates = function (startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        console.log(currDate.toDate());
        dates.push(currDate.clone().toDate());
    }

    return dates;
};

function Calendario(props) {

    // prettier-ignore
    const hours = [
        '12a', '1a', '2a', '3a', '4a', '5a', '6a',
        '7a', '8a', '9a', '10a', '11a',
        '12p', '1p', '2p', '3p', '4p', '5p',
        '6p', '7p', '8p', '9p', '10p', '11p'
    ];

    let limits = props.limits;

    const pieces = [
        // Range of a piece can be specified by property min and max,
        // where min will be set as -Infinity if ignored,
        // and max will be set as Infinity if ignored.
        { min: -Infinity, max: limits[0], label: 'Muy bajo', color: "#D00218" },
        { min: limits[0], max: limits[1], label: 'Bajo', color: "#F4A723" },
        { min: limits[1], max: limits[2], label: 'Dentro del intervalo', color: "#7ED31D" },
        { min: limits[2], max: limits[3], label: 'Alto', color: "#64B8E6" },
        { min: limits[3], max: Infinity, label: 'Muy alto', color: "#1976d2" },
        // Label of the piece can be specified.
        // {min: 10, max: 200, label: '10 to 200 (custom label) '},
        // Color of the piece can be specified.
        // {value: 123, label: '123 (custom special color) ', color: 'grey'},

    ]
    console.log(props.data);
    // prettier-ignore

    let days = Object.keys(props.data);
    // enumerateDaysBetweenDates(daysSorted[0], daysSorted[daysSorted.length-1])
    let daysSorted = days.sort(function (a, b) { return moment(a[0]) - moment(b[0]) });
    let daysbetween = enumerateDaysBetweenDates(moment(props.startDate).add(-1, 'days'),
        moment(props.startDate).add(props.bloqueTiempo + 1, 'days'));
    let labels = daysbetween.map((day) => moment(day).format("DD/MM/YYYY")).reverse();
    // let labels = daysSorted.map((day)=> moment(day).format("DD/MM/YYYY"));
    let data = [];

    // for (const i of daysSorted) {
    //     for (const j of props.data[i]) {
    //         let index = daysSorted.findIndex((value)=> 
    //         value === i
    //         );
    //         console.log(index);
    //         data.push([moment(i).format("DD/MM/YYYY"),,]);
    //     }   
    // }
    // for (let i=0; i<daysSorted.length; i++){
    //     for (let j=0; j<props.data[daysSorted[i]].length; j++){
    //         let dp = props.data[daysSorted[i]][j];
    //         data.push([i,parseInt(dp[0],10),dp[1]]);
    //     }   
    // }
    for (let i = 0; i < labels.length; i++) {
        data.push([])
        for (let j = 0; j < hours.length; j++) {
            data[i].push([])
        }
    }
    let data3 = [];
    for (let i = 0; i < daysSorted.length; i++) {
        let day = moment(daysSorted[i]).format("DD/MM/YYYY");
        let day_index = labels.indexOf(day);
        for (let j = 0; j < props.data[daysSorted[i]].length; j++) {
            let dp = props.data[daysSorted[i]][j];
            data3.push([parseInt(dp[0], 10), day_index, dp[1], 150, 150, dp[1]]);
            data[day_index][parseInt(dp[0], 10)].push(dp[1]);
        }
    }
    let data2 = []
    for (let i = 0; i < labels.length; i++) {
        data2.push([])
        for (let j = 0; j < hours.length; j++) {
            if (data[i][j].length === 0) {
                data[i][j] = "-";
            } else {
                data2[i].push(i, j, data[i][j].reduce((a, b) => a + b, 0) / data[i][j].length);
            }
        }
    }

    // console.log(data2);
    data = data2.map(function (item) {
        return [item[1], item[0], item[2] || '-'];
    });;
    console.log(data);

    // const days = [
    //     'Saturday', 'Friday', 'Thursday',
    //     'Wednesday', 'Tuesday', 'Monday', 'Sunday'
    // ];
    // prettier-ignore
    // const data = [[0, 0, 54], [0, 1, 70] , [0, 3, 200], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5], [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0], [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8], [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2], [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0], [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]]
    //     .map(function (item) {
    //     return [item[1], item[0], item[2] || '-'];
    // });
    function getColor(value) {
        return pieces.filter(item => value >= item.min & value < item.max)[0].color;
    }

    let option = {
        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'cross'
            },
            formatter: function (param) {
                // console.log(data3);
                let dots = data3.filter(item => (item[0] === param.data[0] && item[1] === param.data[1]));
                let out = dots.map(item => `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${getColor(item[2])};"></span>` + " " + labels[item[1]] + " <strong>" + item[2] + "</strong>");
                return out.join("<br/>");
            },
        },
        grid: {
            height: '80%',
            // top: '10%'
        },
        xAxis: {
            type: 'category',
            data: hours,
            position: "top",
            splitArea: {
                show: true
            }
        },
        yAxis: {
            type: 'category',
            data: labels,
            splitArea: {
                show: true
            }
        },
        visualMap: {
            calculable: true,
            // orient: 'vertical',
            // left: 'left',
            type: 'piecewise',
            orient: 'horizontal',
            left: 'center',
            top: 5,
            pieces: pieces,
            // bottom: '0%'
        },
        series: [
            {
                name: 'Glicemias',
                type: 'heatmap',
                data: data3,
                label: {
                    show: false
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                tooltip: {

                },
            },
        ],
    };
    return <ReactECharts option={option} />
}


function Grafico(props) {
    let gluco = props.data.sort(function (a, b) { return moment(a[0]) - moment(b[0]) });
    let cross = "path://M55.931,47.463L94.306,9.09c0.826-0.827,0.826-2.167,0-2.994L88.833,0.62C88.436,0.224,87.896,0,87.335,0 c-0.562,0-1.101,0.224-1.498,0.62L47.463,38.994L9.089,0.62c-0.795-0.795-2.202-0.794-2.995,0L0.622,6.096 c-0.827,0.827-0.827,2.167,0,2.994l38.374,38.373L0.622,85.836c-0.827,0.827-0.827,2.167,0,2.994l5.473,5.476 c0.397,0.396,0.936,0.62,1.498,0.62s1.1-0.224,1.497-0.62l38.374-38.374l38.374,38.374c0.397,0.396,0.937,0.62,1.498,0.62 s1.101-0.224,1.498-0.62l5.473-5.476c0.826-0.827,0.826-2.167,0-2.994L55.931,47.463z";
    gluco = gluco.filter(lectura => moment(lectura[0]) >= moment(props.startDate).startOf('day') & moment(lectura[0]) <= moment(props.startDate).add(props.bloqueTiempo, 'days').startOf('day'));
    let limits = props.limits;
    let normal = gluco.filter(lectura => lectura[1] > limits[1] & lectura[1] <= limits[2]);
    let upper = gluco.filter(lectura => lectura[1] > limits[2]);
    let critical = gluco.filter(lectura => lectura[1] <= limits[1]);
    const groupByCategory = gluco.reduce((group, product) => {
        const day = moment(product[0]).startOf('day');
        group[day] = group[day] ?? [];
        group[day].push(product[1]);
        return group;
    }, {});

    const groupByHours = gluco.reduce((group, product) => {
        const day = moment(product[0]).startOf('day');
        group[day] = group[day] ?? [];
        // console.log(product[0]);
        group[day].push([moment(product[0]).format("HH"), product[1]]);
        return group;
    }, {});

    let average = [];
    for (var key in groupByCategory) {
        var arr = groupByCategory[key];
        average.push([moment(key).add(12, 'hours').utc().format(), (arr.reduce((a, b) => a + b, 0) / arr.length).toFixed(0)]);
    }

    const options = {
        animation: true,
        // title: {
        //     text: 'Temperature Change in the Coming Week'
        // },
        tooltip: {
            trigger: 'axis'
        },
        legend: { show: false },
        toolbox: {
            show: false,
            feature: {
                dataZoom: {
                    yAxisIndex: 'none'
                },
                dataView: { readOnly: false },
                // magicType: { type: ['line', 'bar'] },
                restore: {},
                saveAsImage: {}
            }
        },
        grid: {
            top: 32,
            left: 32,
            right: 32,
            bottom: 32,
            containLabel: true,
        },
        xAxis: {
            name: "",
            type: 'time',
            // offset:  3600 * 1000 * 12,
            // align:"left",
            // padding: [3000, 4, 5, 6],
            boundaryGap: [0, 1],
            // nameLocation:"center",
            minorTick: {
                show: false
            },
            min: function (value) {
                return moment(props.startDate).startOf('day');
            },
            max: function (value) {
                return moment(props.startDate).add(props.bloqueTiempo - 1, "d").endOf('day');
            },
            minInterval: 3600 * 1000 * 24,
            maxInterval: 3600 * 1000 * 24,
            splitLine: {
                show: true,
                lineStyle: {
                    color: "#3333"
                }
            },
            axisTick: {
                show: true,
                interval: 1,
            },
            axisLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                }
            },
            axisLabel: {
                inteval: 1,
                formatter: (function (value) {
                    return moment(value).format("D");
                })
            }
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}'
            }
        },
        series: [
            {
                name: "",
                type: "line",
                markLine: {
                    silent: true,
                    symbol: ["none", "none"],
                    lineStyle: {
                        color: '#B2C6A6',
                        type: "solid",
                    },
                    label: {
                        position: "start",
                        color: "#B2C6A6",
                        distance: [35, 0],
                    },
                    data: [{ yAxis: limits[1] }, { yAxis: limits[2] }]
                },
                markArea: {
                    itemStyle: {
                        color: "#25AB22",
                        opacity: "0.2",
                        borderType: "dashed",
                    },
                    data: [
                        [{ yAxis: limits[1] }, { yAxis: limits[2] }],
                    ],
                },
                // data:[["2022-07-01T08:04:01.339Z", 60]],
            },
            { //Promedios
                name: "Promedio diario",
                type: "line",
                data: average,
                symbolSize: 12,
                symbol: "circle",
                lineStyle: {
                    color: "#000",
                },
                itemStyle: {
                    color: "#000",
                },
            },
            { //normales
                name: 'Mg/dL',
                type: 'scatter',
                symbolSize: 12,
                data: normal,
                symbol: cross,
                color:"#7AB9DF",
                // itemStyle: {
                //     color: "rgba(0, 0, 0, 1)"
                // },
                markLine: {
                    silent: true,
                    symbol: ["none", "none"],
                    lineStyle: {
                        color: '#F00',
                        type: "solid",
                    },
                    label: {
                        position: "start",
                        color: "#F00",
                        distance: [35, 0]
                    },
                    data: [
                        {
                            yAxis: limits[0]
                        }
                    ]
                },
            },
            { //abajo
                name: 'Mg/dL',
                type: 'scatter',
                data: critical,
                symbolSize: 12,
                symbol: cross,
                color: "#F00",
            },
            { //arriba
                name: 'Mg/dL',
                type: 'scatter',
                data: upper,
                symbolSize: 12,
                symbol: cross,
                color: "#7AB9DF",
            },
        ]
    };

    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return <Paper>
        {/* <Grid item container> */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Tendencia" value="1" />
                            <Tab label="Diario" value="2" />
                            {/* <Tab label="Item Three" value="3" /> */}
                        </TabList>
                    </Box>
                    <TabPanel value="1"><ReactECharts option={options} /></TabPanel>
                    <TabPanel value="2"><Calendario data={groupByHours} limits={limits} startDate={props.startDate} bloqueTiempo={props.bloqueTiempo} /></TabPanel>
                    {/* <TabPanel value="3">Item Three</TabPanel> */}
                </TabContext>
            </Box>
        </Box>
        {/* <Grid item xs p={1}>Tendencia</Grid>
        <Divider></Divider> */}
        {/* <Grid container item p={1}> */}

        {/* </Grid> */}
        {/* </Grid> */}
    </Paper>;
};

export default Grafico;