import React from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
// import 

function Semaforo(props) {

    // let props.porcentajes=[10,12,13,15,16];

    const options = {

        width:70,
        grid: {
            left: '3%',
            right: '75%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',

                show: false
            }
        ],
        yAxis: [
            {
                type: 'value',
                min: 0,
                max: 300,

            }
        ],
        series: [
            {
                name: 'Muy bajo',
                type: 'bar',
                stack: 'glucemia',
                roundCap: true,
                itemStyle: {
                    borderRadius: [0, 0, 10000, 10000],
                    color: "#D00218",
                    opacity: 0.8,
                },
                data: [props.limits[0]],
                emphasis: {
                    focus: true,
                },
                label: {
                    normal: {

                        position: 'right',
                        distance: 10,
                        show: true,
                        formatter: [
                            `{a|—} {b|${props.porcentajes[0]}%} {x|Muy bajo}`
                        ].join('\n'),
                        rich: {
                            a: {
                                fontSize: 14,
                            },
                            b: {
                                fontSize: 18,
                            },
                            x: {
                                fontSize: 14,
                            }
                        },
                        padding: 10,
                        fontSize: 14,
                        color: '#000'
                    }
                }
            },
            {
                name: 'Bajo',
                type: 'bar',
                stack: 'glucemia',
                itemStyle: {
                    color: "#F4A723",
                },
                data: [props.limits[1]-props.limits[0]],
                emphasis: {
                    focus: true,
                },
                label: {
                    normal: {

                        position: 'right',
                        distance: 10,
                        show: true,
                        formatter: [
                            `{a|—} {b|${props.porcentajes[1]}%} {x|Bajo}`
                        ].join('\n'),
                        rich: {
                            a: {
                                fontSize: 14,
                            },
                            b: {
                                fontSize: 18,
                            },
                            x: {
                                fontSize: 14,
                            }
                        },
                        padding: 10,
                        fontSize: 14,
                        color: '#000'
                    }
                }
            },
            {
                name: 'Dentro del intervalo',
                type: 'bar',
                itemStyle: {

                    color: "#7ED31D",

                },
                stack: 'glucemia',
                data: [props.limits[2]-props.limits[1]],
                emphasis: {
                    focus: true,
                },
                label: {
                    normal: {

                        position: 'right',
                        distance: 10,
                        show: true,
                        formatter: [
                            `{a|—} {b|${props.porcentajes[2]}%} {x|Ideal}`
                        ].join('\n'),
                        rich: {
                            a: {
                                fontSize: 14,
                            },
                            b: {
                                fontSize: 18,
                            },
                            x: {
                                fontSize: 14,
                            }
                        },
                        padding: 10,
                        fontSize: 14,
                        color: '#000'
                    }
                }
            },
            {
                name: 'Alto',
                type: 'bar',
                itemStyle: {

                    color: "#64B8E6",

                },
                stack: 'glucemia',
                data: [props.limits[3]-props.limits[2]],
                emphasis: {
                    focus: true,
                },
                label: {
                    normal: {

                        position: 'right',
                        distance: 10,
                        show: true,
                        formatter: [
                            `{a|—} {b|${props.porcentajes[3]}%} {x|Alto}`
                        ].join('\n'),
                        rich: {
                            a: {
                                fontSize: 14,
                            },
                            b: {
                                fontSize: 18,
                            },
                            x: {
                                fontSize: 14,
                            }
                        },
                        padding: 10,
                        fontSize: 14,
                        color: '#000'
                    }
                }
            },
            {
                name: 'Muy alto',
                type: 'bar',
                stack: 'glucemia',
                roundCap: true,
                itemStyle: {
                    borderRadius: [10000, 10000, 0, 0],
                    color: "#1976d2",
                },
                emphasis: {
                    focus: true,
                },
                data: [350-props.limits[3]],
                label: {
                    normal: {

                        position: 'right',
                        distance: 10,
                        show: true,
                        formatter: [
                            `{a|—} {b|${props.porcentajes[4]}%} {x|Muy Alto}`
                        ].join('\n'),
                        rich: {
                            a: {
                                fontSize: 14,
                            },
                            b: {
                                fontSize: 18,
                            },
                            x: {
                                fontSize: 14,
                            }
                        },
                        padding: 10,
                        fontSize: 14,
                        color: '#000'
                    }
                }
            },


        ]
    };

    return <div className='semaforo'><ReactECharts option={options} /></div>
};

export default Semaforo;