import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, MenuItem, Popover, Select, Typography } from '@mui/material';
import "./styles/FiltroEdad.css"

export default function TransitionsPopper(props) {
    // const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setOpen((previousOpen) => !previousOpen);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    let Options=["Todos","<=2", "2-5", ">5"]
    // const [daily_filter, setdaily_filter] = React.useState(daily_filterOptions[2]);

    const handleChange = (event, newValue, activeThumb) => {
      if (newValue.props.value===""){
        props.setdaily_filter(undefined);  
      }else{
        props.setdaily_filter(newValue.props.value);
      }
      handleClose();
      
        // return "";
      };

    function displayOption(value){
      if (value==="" || value===undefined){
        return "Todos";
      }
      if (value==="1"){
        return Options[1];
      }
      if (value==="2"){
        return Options[2];
      }
      if (value==="3"){
        return Options[3];
      }
    }
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <div>
            {/* <span classname="filterbutton">Test</span> */}

            <Typography variant="body1" display="inline" sx={{color:"#ABA9AA"}}>Mediciones diarias: </Typography>
            <Typography className="filterbutton" variant="body1" sx={{color:"#1976d2"}} display="inline" aria-describedby={id} onClick={handleClick}>
              {displayOption(props?.daily_filter)}
            </Typography>
            
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                {/* {({ TransitionProps }) => ( */}
                    {/* <Fade {...TransitionProps} timeout={350}> */}
                        <Box sx={{ border: 1, p:4,width:"300px", height:"3em",bgcolor: 'background.paper'}}>
                          
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Mediciones diarias</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={props.daily_filter}
                              label="Mediciones diarias"
                              defaultValue={""}
                              onChange={handleChange}
                            >
                              <MenuItem value={"1"}>{Options[1]}</MenuItem>
                              <MenuItem value={"2"}>{Options[2]}</MenuItem>
                              <MenuItem value={"3"}>{Options[3]}</MenuItem>
                              <MenuItem value={""}>{Options[0]}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                    {/* </Fade> */}
                {/* )} */}
            </Popover>
        </div>
    );
}