import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Grid, Paper, Divider, Modal, Typography, Button } from "@mui/material";
import {useEffect, useState, useContext } from "react";
import axios from 'axios';
import moment from "moment";


export default function DetalleHba1c(props) {
    const [modal, setModal]= useState(false);
    const [data, setData] = useState([]);

   // https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/allhba1c

   useEffect(()=>{
    axios.get("https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/allhba1c", 
    {
        params:{
            patient_id: props.id,
        },
        headers:{
          Authorization: props.IdToken}

    }).then(response => {
      if(response.data['Items']){
        setData(response.data['Items']);
        console.log(response.data['Items']);
      }
       
      });
},[modal]);

      

      let fechas = Object.keys(data);
      let valores = Object.values(data);

      const rows= fechas.map(function(item, index){
        return [item, valores[index]]
    });
   

    const abrirCerrarModal =()=>{
        setModal(!modal);
      }

    function createData(fecha, hba1c) {
        return { fecha, hba1c };
    }
      
      
    const tabla = (
        <Grid sx={{
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #000',
            borderRadius: '5px',
            borderColor:'white',
            boxShadow: 5 ,
            padding: '30px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
        }}>
        <Grid item xs p={1} align={'center'} color={'#1976d2'}><Typography>HISTORIAL DE HBA1C</Typography></Grid>
        <Divider></Divider>
        <TableContainer component={Paper}  sx={{ maxHeight: 440, mt:2}} >
          <Table sx={{ minWidth: 650,  maxHeight: '150px'}} aria-label="simple table">
            <TableHead sx={{backgroundColor:'#1976d2'}}>
              <TableRow>
                <TableCell sx={{color:'#ffffff'}}>FECHA</TableCell>
                <TableCell sx={{color:'#ffffff'}}>HBA1C</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.reverse().map((row, index) => (
                
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                
                  <TableCell align="left">{moment(row[0]).format('DD/MM/YYYY')}</TableCell>
                  <TableCell align="left">{row[1]}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        
      </TableContainer>
        <div align='right'>
        <Button variant="contained" size="small" sx={{mt:2, p:1, width:'30%', backgroundColor:'#1976d2'}} onClick={()=>abrirCerrarModal()}>Cerrar</Button>
        </div>
        
 
        </Grid>
    
        );
  return (
    <div >
    <Button  variant="text" size="small" sx={{m:1}} onClick={()=>abrirCerrarModal()}>Ver historial</Button>
    <Modal open={modal} onClose={abrirCerrarModal}>
        {tabla}
    </Modal>
  </div>
    
  );
}