import * as React from 'react';
import { useContext } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Context } from "./hooks/context";
import axios from 'axios';
import "./styles/LoginPage.css"

function AnimatedBackground(){
  return(
    <div className='box'>
    <div className='wave -one'></div>
    <div className='wave -two'></div>
    <div className='wave -three'></div>
  </div>
  
  );
  
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="">
            Voyager Health
            </Link>{''}
            {'. '}
            All rights reserved.
        </Typography>
    );
}

const theme = createTheme();

export default function SignInSide() {
    let navigate = useNavigate();
    
    const [Errorlogin, setErrorLogin] = React.useState(false);

    const {
        setAccessToken,
        setIdToken,
      } = useContext(Context);

    const baseURL = "https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/login";

    const handleSubmit = (event) => {
        event.preventDefault();
        // navigate("/");
        const data = new FormData(event.currentTarget);
        login(data.get('username'),data.get('password'))

    };

    function login(username, password) {
        axios
          .post(baseURL, {
            // body: {
                username: username,
                password: password,
            // }
          })
          .then((response) => {
            setErrorLogin(false);
            setAccessToken(response["data"]["AccessToken"]);
            setIdToken(response["data"]["IdToken"]);
            navigate("/");
          },(error)=>{
            setErrorLogin(true);
            console.log(error);
          });
      }
    

    return (
        <ThemeProvider theme={theme} >
            <AnimatedBackground/>
            <Grid container direction='row' justifyContent={'center'} component="main" sx={{ height: '100vh'}}>
           
                <CssBaseline />
              
                <Grid item xs={12} sm={8} md={5} component={Paper} align={"center"} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            
                        {/* <Avatar sx={{ m: 1, width: 56, height: 56, bgcolor: 'primary.main' }}>V</Avatar> */}
                        <Stack direction="row" style={{ justifyContent: "center", display: "flex", bgcolor: 'primary.main' }} spacing={1}>
                            {/* <Avatar
                                alt="Voyager"
                                sx={{ width: 56, height: 56, bgcolor: 'primary.main' }}
                            >
                                V
                            </Avatar> */}
                            <Box component="img" src="./voyager.png" 
                                sx={{
                                height: 70,
                                //   width: 250,
                                // maxHeight: { xs: 233, md: 167 },
                                // maxWidth: { xs: 350, md: 250 },
                                }}>
                            </Box>
                        </Stack>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <Typography component="span" style={{color:"red", width:"100%"}}>{Errorlogin && <span>ERROR: Usuario o contraseña equivocados.</span>}</Typography>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                error={Errorlogin}
                                id="username"
                                label="Nombre de usuario"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                error={Errorlogin}
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Recuérdame"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Iniciar sesión
                            </Button>
                            <Grid container>
                            
                                <Grid item >
                                    <Link href="#" variant="body2" >
                                        ¿Olvidaste tu contraseña?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}