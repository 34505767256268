import React from "react";
import { ContextProvider } from "./hooks/context";
import RouterPage from "./RouterPage"

// import './App.css';

function App() {


  return (
    <ContextProvider>
      <RouterPage></RouterPage>
    </ContextProvider>
  );
}


export default App;
