import { useContext, useEffect, useState } from "react";
import NavBar from "./NavBar.js";
import TopBar from "./TopBar.js";
import PatientTable from "./PatientTable.js";


import "./styles/Home.css"


function Home() {

    const [index_name, setindex_name] = useState("Profile");
    const [gender, setgender] = useState(undefined);
    const [begin_date, setbegin_date] = useState(undefined);
    const [end_date, setend_date] = useState(undefined);
    const [diabetes, setdiabetes] = useState(undefined);
    const [diagnosis, setdiagnosis] = useState(undefined);
    const [daily_filter, setdaily_filter] = useState(undefined);
    const [hba1c, sethba1c] = useState(undefined);
    const [scan_index_forward, setscan_index_forward] = useState(undefined);

    return <div className="MainWindow" style={{backgroundColor:'white'}}>
        
            <NavBar />
            {/* {JSON.stringify(User)}
            {IdToken} */}
            <div className="MainContent"  >
            <TopBar 
            setindex_name={setindex_name} 
            index_name={index_name}
            setgender={setgender}
            gender={gender}
            setbegin_date={setbegin_date}
            begin_date={begin_date}
            setend_date={setend_date}
            end_date={end_date}
            setdiagnosis={setdiagnosis}
            diagnosis={diagnosis}
            setdiabetes={setdiabetes}
            diabetes={diabetes}
            setdaily_filter={setdaily_filter}
            daily_filter={daily_filter}
            sethba1c={sethba1c}
            hba1c={hba1c}
            setscan_index_forward={setscan_index_forward}
            scan_index_forward={scan_index_forward}
            />
            
            <PatientTable 
                index_name={index_name}
                gender={gender}
                begin_date={begin_date}
                diabetes={diabetes}
                end_date={end_date}
                diagnosis={diagnosis}
                daily_filter={daily_filter}
                hba1c={hba1c}
                scan_index_forward={scan_index_forward}
            />
            
            {/* <h2>Gestion de los pacientesskjhadsasd</h2> */}
            
            </div>
        </div>;
  }
  
  export default Home;