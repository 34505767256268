import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useRanger } from "react-ranger";
import {Grid, Paper, Divider, Modal, Typography, Button } from "@mui/material";
import axios from 'axios';
import {useEffect, useState, useContext } from "react";

const GlobalStyles = createGlobalStyle`
  body {
   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
   
  }
`;

export const Track = styled("div")`
  height: 10px;
  width: 800px;
`;

export const Tick = styled("div")`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    height: 5px;
    width: 2px;
    transform: translate(-50%, 0.7rem);
  }
`;

export const TickLabel = styled("div")`
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
`;

export const Segment = styled("div")`
  background: ${props =>
    props.index === 0
      ? "#d93546"
      : props.index === 1
      ? "#f4a723"
      : props.index === 2
      ? "#7ed31d"
      : props.index === 3
      ? "#64b8e6"
      : "#1976d2"};
  height: 100%;
  border-radius: 5px;
`;

export const Handle = styled("div")`
background: ${props =>
  props.index === 0
    ? "#d93546"
    : props.index === 1
    ? "#f4a723"
    : props.index === 2
    ? "#7ed31d"
    : props.index === 3
    ? "#64b8e6"
    : "#1976d2"};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  color: white;
  font-weight: ${props => (props.active ? "bold" : "normal")};
  transform: ${props =>
    props.active ? "translateY(-100%) scale(1.3)" : "translateY(0) scale(0.9)"};
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;




export default function SliderRangosGlucosa(props) {
  // console.log(props.limits)
  const [values, setValues] = React.useState(props.limits);
  const[oldValues, setOldValues]= React.useState(props.limits);

  React.useEffect(() => {
    setValues(props.limits);
    setOldValues(props.limits);
}, [props.limits])


  const { getTrackProps, ticks, segments, handles } = useRanger({
    min: 0,
    max: 350,
    stepSize: 1,
    values,
    onChange: setValues
  });

  const [modal, setModal]= useState(false);




 const actualizar=()=>{
  abrirCerrarModal();
 }


  const abrirCerrarModal =()=>{
    setModal(!modal);
  }

  
const cancelar =(event) =>{
  setValues(oldValues);
  abrirCerrarModal();
}
  const enviarDatos=(event)=>{    
     var data = `{"limits":"[${`${values[0]},${values[1]},${values[2]},${values[3]}`}]","patient_id":"${props.id}"}`;
      
     var config = {
     method: 'post',
     url: 'https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/limitsglicemia',
     headers: { 
        'Authorization': props.IdToken, 
        'Content-Type': 'text/plain'
     },
     data : data
     };

    axios(config)
    .then(function () {
      setOldValues(values);
      props.setLimits(values);
    })
    .catch(function (error) {
    console.log(error);
    });

     abrirCerrarModal()

}



const body=(
  <Grid sx={{
      position: 'absolute',
      backgroundColor: 'white',
      border: '1px solid #000',
      borderRadius: '5px',
      borderColor:'white',
      boxShadow: 5 ,
      padding: '24px 5px 24px 5px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)'
  }}>
      <Grid item xs p={2} textAlign={'center'}>
          <Typography m={1} color={'#1976d2'}> <strong>ACTUALIZAR NIVELES DE GLICEMIA</strong></Typography>
          <Divider></Divider>
      </Grid>
          <Grid item container xs p={1} textAlign={'center'} justifyContent={'center'} >
            <Track {...getTrackProps()}>
              {ticks.map(({ value, getTickProps }) => (
                <Tick {...getTickProps()}>
                  <TickLabel>{value}</TickLabel>
                </Tick>
                ))}
                {segments.map(({ getSegmentProps }, i) => (
                  <Segment {...getSegmentProps()} index={i} />
                ))}
                {handles.map(({ value, active, getHandleProps }) => (
                  <button
                    {...getHandleProps({
                      style: {
                        appearance: "none",
                        border: "none",
                        background: "transparent",
                        outline: "none"
                      }
                    })}
                  >
                    <Handle active={active}>{value}</Handle>
                  </button>
                ))}
              </Track>
              <br />
              <br />
              <br />
              <div>
              <Button variant="contained" size="small" sx={{m:1, p:2}} onClick={()=>cancelar()}>Cancelar</Button>
                  <Button variant="contained" size="small" sx={{m:1, p:2}} onClick={()=>enviarDatos()}>Guardar</Button>
                 
              </div> 
          </Grid>
  </Grid>
)




  return (
    <Grid container>
      <Button variant="contained" size="small" xs={12} onClick={()=>actualizar()}>Actualizar </Button>
      <Modal open={modal} onClose={abrirCerrarModal}>
          {body}
      </Modal>
    </Grid>
  );
}




