import React from "react";
import { Box, Button, Modal, FormControl, Stack,Grid, Typography, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import swal from 'sweetalert';
import { Context } from "./hooks/context";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import './styles/PatientEmails.css'
import CircularProgress from '@mui/material/CircularProgress';


function PatientEmails(props) {
  const {register, formState:{errors}, watch, resetField, handleSubmit} = useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal]= useState(false);
  const [addNewEmail, setAddNewEmail] = useState(false);
  const {
    IdToken,
  } = useContext(Context);
  const [emails, setEmails] = React.useState([]);
  const id = props.id

  let paramsUrl = `https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patient/${id}/email`
  
   useEffect(() => {
    var config = {
      method: 'get',
      url: paramsUrl,
      headers: {
        Authorization: IdToken,
      },
    };
    axios(config)
      .then(function (response) {
        setEmails(JSON.parse(response.data.Items));
      })
      .catch(function (error) {
        setEmails([]);
      });
  }, [emails]);


  const alertaExitoEliminado = ()=>{
    swal({
      title: 'Email eliminado',
      text: 'Email eliminado con éxito',
      icon: 'success',
      button: 'Aceptar'
    });
  }
  const alertaErrorEliminado = ()=>{
    swal({
      title: 'Algo salió mal',
      text: 'Error al eliminar email',
      icon: 'error',
      button: 'Aceptar'
    });
  }

    const alertaExito = ()=>{
        swal({
          title: 'Email creado',
          text: 'Email creado con éxito',
          icon: 'success',
          button: 'Aceptar'
        });
      }
    
      const alertaError = (message)=>{
        swal({
          icon: 'error',
          title: 'Algo salió mal',
          text: 'Error al crear email'+ '\n' + message,
          button: 'Aceptar'
        })
      }


  
    const onSubmit = (e)=>{    
      console.log(e.newEmail)
      setIsLoading(true);     
             var data = JSON.stringify({
              "email": e.newEmail,  
              "id":props.id,           
            });

              var config = {
              method: 'post',
              url: `https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patient/${id}/email`,
              headers: { 
                  'Authorization': IdToken, 
              },
              data : data
              };
          
              axios(config)
              .then(function (response) {
              alertaExito();
              console.log(JSON.stringify(response.data));
              setIsLoading(false);
              })
              .catch(function (error) {
              alertaError(error);
              console.log(error);
              
              });
              setAddNewEmail(false);
    
  }


  const deleteEmail=(email)=>{
    setIsDisabled(true);
    console.log('email eliminado' + email)
    var data = JSON.stringify({
      "email": email,  
      "id":props.id,           
    });

      var config = {
      method: 'DELETE',
      url: `https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patient/${id}/email`,
      headers: { 
          'Authorization': IdToken, 
      },
      data : data
      };
  
      axios(config)
      .then(function (response) {
      alertaExitoEliminado();
      setIsDisabled(false);
      console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        alertaErrorEliminado();
        setIsDisabled(false)
      console.log(error);
      });
    
  }

    const verEmails=()=>{
        abrirCerrarModal();
    }

    const abrirCerrarModal =()=>{
      resetField("newEmail");    
      setAddNewEmail(false);  
      setModal(!modal);
    }


    
    const body=(
        <Grid sx={{
            overflowY: "auto",
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #000',
            borderRadius: '5px',
            borderColor:'white',
            boxShadow: 5 ,
            padding: '24px 5px 24px 5px',
            top: '50%',
            left: '50%',
            width : '35%',
            transform: 'translate(-50%,-50%)'
        }}>
            <Grid item xs p={1} textAlign={'center'}>
                
                <table >
                <thead>
                    <tr>
                        <th><Typography>Emails</Typography></th>
                        <th><Typography>Eliminar</Typography></th>
                    </tr>
                </thead>
                <tbody textAlign={'left'}>
                  <tr>
                    <td>
                      {emails.map(item => <Typography sx={{p:1.5}}>{item.email}</Typography>)}
                    </td>
                    <td >
                    {emails.map((item, index)=> item.sk.includes("PROFILE")?
                      <p className="deleteRow"><Button disabled={true} aria-label="delete"><DeleteOutlineTwoToneIcon /> </Button></p>:
                      <p className="deleteRow"> <Button disabled={isDisabled} onClick={()=>deleteEmail(item.email)} aria-label="delete"><DeleteOutlineTwoToneIcon /></Button></p> 
                    )}
                    </td>
                    </tr>                   
                </tbody>
            </table>   
            <div className="btn-new">
                            <Button variant="text" size="large" alignSelf={'left'} onClick={()=>setAddNewEmail(true)} >Añadir nuevo</Button>
                  </div>   
            </Grid>
          
                <div textalign={'center'} justifycontent={'center'} display={'flex'} flexDirection={'column'} alignContent={'center'}>
                      
                    {addNewEmail && (
                      <div> 
                        {errors.newEmail?.type ==='pattern' && <Typography sx={{ml:1, width:'80%', color:'red'}} ariant="caption" display="block" gutterBottom>Formato de email incorrecto</Typography>} 
                      {errors.newEmail?.type ==='required' && <Typography sx={{ml:1, width:'80%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese un email</Typography>}
                      
                      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{width:'100%', alignItems:'center', justifyContent:'center', display:'flex'}}> 
                      <TextField  required label="Nuevo email" sx={{width:'70%', m:1, textAlign:"left"}} name='newEmail' {...register('newEmail', {required: true, pattern: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}\b/ })} /> 
                      <Button variant="contained" size="small" sx={{width:'15%',p:1.5,mr:0.5,textAlign:"center",backgroundColor:"#2C3E50",'&:hover': {backgroundColor: '#3C546C', color:'white'}}}  onClick={()=>setAddNewEmail(false)} >Cancelar</Button>
                      <Button variant="contained" size="small" sx={{width:'15%', p:1.5, textAlign:"center"}}  type="submit">Guardar</Button>
                     </Box></div>
                  
                    )}
                    {isLoading ? <Stack alignItems="center"><CircularProgress /></Stack>:<div></div> }
                </div>
                
                <Grid  textAlign={'center'} justifyContent={'center'} alignSelf={'end'}>
                        <Button variant="contained" size="large" sx={{ alignSelf:'flex-end' ,m:1, p:1, width: '45%', backgroundColor:"#2C3E50",'&:hover': {backgroundColor: '#3C546C', color:'white'}}}  onClick={()=>abrirCerrarModal()}>Cerrar</Button>
                </Grid>
                
        </Grid>
      )
return (
    <div>
        <Button className="button-round" variant="text" size="small" onClick={()=>verEmails()}>Ver emails</Button>
        <Modal open={modal} onClose={abrirCerrarModal}>
            {body}
        </Modal>
    </div>
);
}

export default PatientEmails;