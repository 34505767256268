import { Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, SvgIcon, TextField, Modal, Typography } from "@mui/material";
import NavBar from "./NavBar.js";
import { Context } from "./hooks/context";
import axios from 'axios';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightlightIcon from '@mui/icons-material/Nightlight';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import moment from "moment";
import { es } from 'date-fns/locale';
import Grafico from "./Grafico.js";
import { styled } from '@mui/material/styles';
import {
    useParams
} from "react-router-dom";

import "./styles/Home.css"
import { useEffect, useState, useContext } from "react";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import Semaforo from "./Semaforo.js";
import SliderRangosGlucosa from './SliderRangosGlucosa'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DetalleHba1c from './DetalleHba1c.js';
import PatientEmails from "./PatientEmails.js";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

// let patients = {
//     "K-1231561": {
//         "nombre": "Jorge Alberto",
//         "apellidos": "Cerecedo Cordoba",
//         "data": [
//             ["2022-04-01T08:38:00.000Z", 218],
//             ["2022-04-02T13:24:00.000Z", 151],
//             ["2022-04-02T14:05:00.000Z", 230],
//             ["2022-04-03T13:26:00.000Z", 151],
//             ["2022-08-01T11:15:00.000Z", 139],
//             ["2022-08-01T20:19:00.000Z", 151],
//             ["2022-08-02T14:39:00.000Z", 254],
//             ["2022-08-03T13:05:00.000Z", 264],

//         ]
//     }
// };




function DetailsBox(props) {
    console.log('props')
    console.log(props)
    // let grey="rgba(0, 0, 0, 0.12)";
    return <Grid p={1} item xs>
        <Typography variant="caption" sx={{ color: "#AAA" }}>{props.name}</Typography>
        {
            <Typography variant="body1">{props?.data ? props.data : <Skeleton variant="text" />}</Typography>
        }
    </Grid>
}



function Calendar(props) {
    const [time, setTime] = useState(moment(props.value));
    const [time2, setTime2] = useState(moment(props.value).add(props.bloqueTiempo, "d"));
    const handleChange = (newValue) => {
        props.setValue(newValue);
        setTime(moment(newValue));
    };

    let days = ["dom", "lun", "mar", "mié", "jue", "vie", "sab"];
    let months = ["ene.", "feb.", "mar", "abr.", "may.", "jun.", "jul.", "ago.", "sep.", "oct.", "nov.", "dic."];


    // let time2;

    useEffect(() => {
        setTime2(moment(time).add(props.bloqueTiempo, "d"));
    }, [time, props.bloqueTiempo, props.value]);

    useEffect(() => {
        setTime(moment(props.value));
    }, [props.value]);


    return <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DatePicker
            label="Custom input"
            value={props.value}
            onChange={handleChange}
            renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ alignItems: 'center', border: "1px solid", borderColor: "rgba(0, 0, 0, 0.12)" }}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={"auto"} className="calendarioIcon">{InputProps?.endAdornment}</Grid>
                        <Grid item xs={0}><input className="inputcalendar" ref={inputRef} {...inputProps} /></Grid>
                        <Grid item container p={1} xs>
                            <Grid item xs><div>{days[time.weekday()]}. {time.date()} {months[time.month()]} de {time.year()}</div></Grid>
                            <Grid item xs={"auto"} container display={{ xs: "none", xl: "block" }}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Grid><ArrowRightAltIcon /></Grid>
                            </Grid>
                            <Grid item xs display={{ xs: "none", xl: "block" }}><div>{days[time2.weekday()]}. {time2.date()} {months[time2.month()]} de {time2.year()}</div></Grid>
                        </Grid>
                    </Grid>
                </Box>

            )}
        />
        {/* </Stack> */}
    </LocalizationProvider>
}


{/* <Paper p={1}>
                        <Grid item xs p={1}>DISTRIBUCION DEL NIVEL DE GLUCEMIA</Grid>
                        <Divider></Divider>
                        <Semaforo porcentajes={[8,13,13,15,16]}/>
                    </Paper> */}

function Estadistica(props) {
    // console.log(props.medicionesxdia);
    return <Paper p={1} m={1} >
        {/* <Grid item container> */}
        <Grid item xs p={1}>ESTADÍSTICA DE GLICEMIA</Grid>
        <Divider></Divider>

        <Grid container item xs p={1}>
            <Grid item container xs>
                <Grid item p={1}><Indicador>{props.medicionesxdia ? props.medicionesxdia : <CircularProgress />}</Indicador></Grid>
                <Grid item p={1} justifyContent="center" alignItems="center" xs>Mediciónes por dia</Grid>
            </Grid>
            <Grid item container xs>
                <Grid item p={1}><Indicador>{props.numerodemediciones ? props.numerodemediciones : <CircularProgress />}</Indicador></Grid>
                <Grid item p={1} justifyContent="center" alignItems="center" xs>Número de mediciones</Grid>
            </Grid>
            <Grid item container xs>
                <Grid item p={1}><Indicador>{props.medicionesxdia ? props.promedioglucemia : <CircularProgress />}</Indicador></Grid>
                <Grid item p={1} justifyContent="center" alignItems="center" xs>Promedio de glicemia</Grid>
            </Grid>
        </Grid>
        {/* </Grid> */}
    </Paper>
}

function Hipoglucemia(props) {
    return <Paper p={1} height={"100%"}>
        {/* <Grid item container> */}
        <Grid item xs p={1}>HIPOGLICEMIA</Grid>
        <Divider></Divider>
        <Grid container item xs p={1}>
            <Grid item container xs={12} direction="column" justifyContent="center" alignItems="stretch">
                <Grid item p={1} ><Typography sx={{ fontSize: 32, display: "inline", fontWeight: 600 }}>{props.hipos[0] + props.hipos[1] + props.hipos[2] + props.hipos[3]}</Typography><Typography sx={{ fontSize: 16, display: "inline", fontWeight: 600 }}> total de hipos</Typography></Grid>
                <Divider variant="middle"></Divider>
                {/* <Grid item p={1} xs><Avatar>25</Avatar></Grid> */}
                <Grid item p={1} justifyContent="center" alignItems="center" xs>
                    <Grid container justifyContent="space-between">
                                                                                                                                                                                                {/* hiposdiamb, hiposdiab, hiposnochemb, hiposnocheb */}
                        <Grid item container xs={6} alignItems="center"><NoiseControlOffIcon htmlColor="#f4a723" /> Bajo</Grid>
                        <Grid item container xs={6} alignItems="center"><WbSunnyIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hipos[1]} durante el día</Typography></Grid>
                        <Grid item container xs={6} alignItems="center" pl={4}><Typography sx={{ fontSize: 24, display: "inline", fontWeight: 600 }}>{props.hipos[3] + props.hipos[1]}</Typography></Grid>
                        <Grid item container xs={6} alignItems="center"><NightlightIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hipos[3]} durante la noche</Typography></Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid container justifyContent="space-between">
                        <Grid item container xs={6} alignItems="center"><NoiseControlOffIcon htmlColor="#F00" />Muy  Bajo</Grid>
                        <Grid item container xs={6} alignItems="center"><WbSunnyIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hipos[0]} durante el día</Typography></Grid>
                        <Grid item container xs={6} alignItems="center" pl={4}><Typography sx={{ fontSize: 24, display: "inline", fontWeight: 600 }}>{props.hipos[2] + props.hipos[0]}</Typography></Grid>
                        <Grid item container xs={6} alignItems="center"><NightlightIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hipos[2]} durante la noche</Typography></Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item p={1} justifyContent="center" alignItems="center" xs>Medicción por dia</Grid>                             */}
        </Grid>


        {/* </Grid> */}
        {/* </Grid> */}
    </Paper>
}

function Hiperglucemia(props) {
    return <Paper p={1} height={"100%"}>
        {/* <Grid item container> */}
        <Grid item xs p={1}>HIPERGLICEMIA</Grid>
        <Divider></Divider>
        <Grid container item xs p={1}>
            <Grid item container xs={12} direction="column" justifyContent="center" alignItems="stretch">
                <Grid item p={1} ><Typography sx={{ fontSize: 32, display: "inline", fontWeight: 600 }}>{props.hiper[0] + props.hiper[1] + props.hiper[2] + props.hiper[3]}</Typography><Typography sx={{ fontSize: 16, display: "inline", fontWeight: 600 }}> total de hipers</Typography></Grid>
                <Divider variant="middle"></Divider>
                {/* <Grid item p={1} xs><Avatar>25</Avatar></Grid> */}
                <Grid item p={1} justifyContent="center" alignItems="center" xs>
                    <Grid container justifyContent="space-between">
                        <Grid item container xs={6} alignItems="center"><NoiseControlOffIcon htmlColor="#64b8e6" /> Alto</Grid>
                        <Grid item container xs={6} alignItems="center"><WbSunnyIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hiper[1]} durante el día</Typography></Grid>
                        <Grid item container xs={6} alignItems="center" pl={4}><Typography sx={{ fontSize: 24, display: "inline", fontWeight: 600 }}>{props.hiper[3] + props.hiper[1]}</Typography></Grid>
                        <Grid item container xs={6} alignItems="center"><NightlightIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hiper[3]} durante la noche</Typography></Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid container justifyContent="space-between">
                        <Grid item container xs={6} alignItems="center"><NoiseControlOffIcon htmlColor="#1976d2" />Muy  Alto</Grid>
                        <Grid item container xs={6} alignItems="center"><WbSunnyIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hiper[0]} durante el día</Typography></Grid>
                        <Grid item container xs={6} alignItems="center" pl={4}><Typography sx={{ fontSize: 24, display: "inline", fontWeight: 600 }}>{props.hiper[2] + props.hiper[0]}</Typography></Grid>
                        <Grid item container xs={6} alignItems="center"><NightlightIcon fontSize="16" htmlColor="#1976d2" /><Typography ml={1} sx={{ fontSize: 16, display: "inline", fontWeight: 100 }}>{props.hiper[2]} durante la noche</Typography></Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item p={1} justifyContent="center" alignItems="center" xs>Medicción por dia</Grid>                             */}
        </Grid>


        {/* </Grid> */}
        {/* </Grid> */}
    </Paper>
}

function Indicador(props) {
    return <div className="indicador">
        <div>{props.children}</div>
        {props.label ? <div>{props.label}</div> : ""}
    </div>;
}

function Estado(props) {
    return <Paper p={1} m={1} sx={{ height: "100%" }}>
        {/* <Grid item container> */}
        <Grid item xs p={1}>ESTADO</Grid>
        <Divider></Divider>
        <Grid container item xs p={1}>
            <Grid item container xs={12}>
                <Grid item p={1} xs={"auto"}><Indicador label="LBQi">0.4</Indicador></Grid>
                <Grid item container xs p={1}>
                    <Grid item xs={12}><Typography >Riesgo de hipoglicemia</Typography></Grid>
                    <Grid item xs={"auto"}><Typography>Bajo</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item p={1} xs={"auto"}><Indicador label="mg/dL">227</Indicador></Grid>
                <Grid item container xs p={1}>
                    <Grid item xs={12}><Typography>Promedio de glicemia</Typography></Grid>
                    <Grid item xs={"auto"}><Typography>Por encima intevalo ideal</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item container xs={12}>
                <Grid item p={1} xs={"auto"}><Indicador label="%">41.9</Indicador></Grid>
                <Grid item container xs p={1}>
                    <Grid item xs={12}><Typography >Variabilidad</Typography></Grid>
                    <Grid item xs={"auto"}><Typography>Intermedio</Typography></Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* </Grid> */}
    </Paper>
}


function PanelItem(props) {
    return (<Box height="100%" sx={{
        gridArea: props.Area,
        bgcolor: 'primary.main'
    }}>{props.children}</Box>);
}

Date.prototype.yyyymmdd = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('-');
};


function ActualizarHba1c(props) {
    // useStyles();
    const [modal, setModal] = useState(false);
    const [fecha, setFecha] = useState(null);
    const [valor, setValor] = useState('');
    const {
        // AccessToken,
        IdToken,
    } = useContext(Context);


    const handleInputChange = (event) => {
        setValor({
            ...valor,
            [event.target.name]: event.target.value
        })
    }
    const abrirCerrarModal = () => {
        setModal(!modal);
    }

    let posthba1cURL = "https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patient/hba1c"

    const enviarDatos = (event) => {
        console.log(fecha, valor);
        let fecha2 = moment(fecha);

        var data = `{"date":"${fecha2.format('YYYY-MM-DD')}" ,"hba1c":"${valor.valor}","patient_id":"${props.id}"}`;

        var config = {
            method: 'post',
            url: posthba1cURL,
            headers: {
                'Authorization': IdToken,
                'Content-Type': 'text/plain'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                props.setLastHBA1C(valor.valor);
                props.setLastHBA1CDate(`${fecha.$D}/${fecha.$M + 1}/${fecha.$y}`)
            })
            .catch(function (error) {
                console.log(error);
            });
        abrirCerrarModal()

    }

    const body = (
        <Grid sx={{
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #000',
            borderRadius: '5px',
            borderColor: 'white',
            boxShadow: 5,
            padding: '16px 32px 24px ',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
        }}>
            <Grid item xs p={2} textAlign={'center'}>
                <Typography m={1} color={'#1976d2'}> <strong> ACTUALIZAR HBA1C</strong></Typography>
                <Divider></Divider>
            </Grid>
            <Grid item container xs p={1} textAlign={'center'} justifyContent={'center'} >
                <div display={'flex'} flexDirection={'column'} >
                    <div >
                        <div>
                            <TextField label='Nuevo Hba1c' id="valor" sx={{ width: '95%', mb: 2 }} name='valor' onChange={handleInputChange} />
                        </div>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                <DatePicker
                                    label="Fecha"
                                    value={fecha}
                                    onChange={(newValue) => {
                                        setFecha(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        sx={{ mt: 1, mb: 1, width: '95%' }} />}
                                    name='fecha' />
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div>
                        <Button variant="contained" size="small" sx={{ m: 1, p: 2 }} onClick={() => enviarDatos()}>Guardar</Button>
                        <Button variant="contained" size="small" sx={{ m: 1, p: 2 }} onClick={() => abrirCerrarModal()}>Cancelar</Button>
                    </div>

                </div>


            </Grid>
        </Grid>
    )
    return (
        <div >
            <Button variant="contained" size="small" sx={{ m: 1 }} onClick={() => abrirCerrarModal()}>Actualizar</Button>
            <Modal open={modal} onClose={abrirCerrarModal}>
                {body}
            </Modal>
        </div>
    );
}


function PatientDetailsPage() {
    moment.locale("es-mx");
    let { id } = useParams();
    const {
        AccessToken,
        IdToken,
    } = useContext(Context);
    const [data, setData] = useState([]);
    const [lastHBA1C, setLastHBA1C] = useState(undefined);
    const [lastHBA1CDate, setLastHBA1CDate] = useState(undefined);
    const [updateHba1c, setUpdateHba1c] = useState(true);
    const [medicionesxdia, setmedicionesxdia] = useState(undefined);
    const [numerodemediciones, setnumerodemediciones] = useState(undefined);
    const [promediodemediciones, setpromediodemediciones] = useState(undefined);
    const [hipos, setHipos] = useState([0, 0, 0, 0]);
    const [hiper, setHiper] = useState([0, 0, 0, 0]);
    const [profile, setProfile] = useState({});
    const [distr, setDistr] = useState(["--", "--", "--", "--", "--"]);
    const [bloqueTiempo, setbloqueTiempo] = useState(14);
    const [fecha, setFecha] = useState(moment(new Date()).add(-bloqueTiempo + 1, 'days').toDate());
    const [finishFecha, setFinishFecha] = useState(moment(new Date()).add(1, 'days').toDate());
    const [limits, setLimits] = useState([50, 70, 180, 250]);

    function distribucionglicemia(data) {
        const mb = data.filter(obj => {
            if (obj[1] < limits[0]) {
                return true;
            }
            return false;
        }).length / data.length;
        const b = data.filter(obj => {
            if (obj[1] >= limits[0] && obj[1] < limits[1]) {
                return true;
            }
            return false;
        }).length / data.length;
        const n = data.filter(obj => {
            if (obj[1] >= limits[1] && obj[1] <= limits[2]) {
                return true;
            }
            return false;
        }).length / data.length;
        const a = data.filter(obj => {
            if (obj[1] > limits[2] && obj[1] <= limits[3]) {
                return true;
            }
            return false;
        }).length / data.length;
        const ma = data.filter(obj => {
            if (obj[1] > limits[3]) {
                return true;
            }
            return false;
        }).length / data.length;
        return [(mb * 100).toFixed(1), (b * 100).toFixed(1), (n * 100).toFixed(1), (a * 100).toFixed(1), (ma * 100).toFixed(1)]
    }

    function calculatehipos(data) {
        function isDay(date) {
            return date.hour() >= 7 && date.hour() < 19
        }

        const hiposdiamb = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] < limits[0] && isDay(date)) {
                return true;
            }
            return false;
        }).length;
        const hiposdiab = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] < limits[1] && isDay(date)) {
                return true;
            }
            return false;
        }).length - hiposdiamb;
        const hiposnochemb = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] < limits[0] && !isDay(date)) {
                return true;
            }
            return false;
        }).length;
        const hiposnocheb = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] < limits[1] && !isDay(date)) {
                return true;
            }
            return false;
        }).length - hiposnochemb;
        return [hiposdiamb, hiposdiab, hiposnochemb, hiposnocheb]
    }

    function calculatehiper(data) {
        function isDay(date) {
            return date.hour() >= 7 && date.hour() < 19
        }

        const hiperdiaMA = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] > limits[3] && isDay(date)) {
                return true;
            }
            return false;
        }).length;

        const hiperdiaA = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] > limits[2] && isDay(date)) {
                return true;
            }
            return false;
        }).length - hiperdiaMA;
        const hipernocheMA = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] > limits[3] && !isDay(date)) {
                return true;
            }
            return false;
        }).length;
        const hipernocheA = data.filter(obj => {
            let date = moment(obj[0]);
            if (obj[1] > limits[2] && !isDay(date)) {
                return true;
            }
            return false;
        }).length - hipernocheMA;
        return [hiperdiaMA, hiperdiaA, hipernocheA, hipernocheMA]
    }

    useEffect(() => {
        setDistr(["--", "--", "--", "--", "--"]);
        setmedicionesxdia(undefined);
        setnumerodemediciones(undefined);
        setpromediodemediciones(undefined);
        setData([]);
        // setFinishFecha(moment(fecha).add(bloqueTiempo, 'days').toDate())
        axios.get("https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/glicemias",
            {
                params: {
                    patient_id: id,
                    start_date: fecha.yyyymmdd(),
                    end_date: moment(fecha).add(bloqueTiempo + 1, 'days').toDate().yyyymmdd(),
                },
                headers: { Authorization: IdToken }
            }).then(response => {
                console.log(response.data[id]["data"], fecha, finishFecha);
                setData(response.data[id]["data"]);
                if (response.data[id]["data"].length > 0) {
                    setDistr(distribucionglicemia(response.data[id]["data"]));
                    setmedicionesxdia((response.data[id]["data"].length / bloqueTiempo).toFixed(1));
                    setnumerodemediciones(response.data[id]["data"].length);
                    setpromediodemediciones((response.data[id]["data"].reduce((partialSum, a) => partialSum + a[1], 0) / response.data[id]["data"].length).toFixed(1));
                    setHipos(calculatehipos(response.data[id]["data"]));
                    setHiper(calculatehiper(response.data[id]["data"]));
                } else {
                    setDistr(["--", "--", "--", "--", "--"]);
                    setmedicionesxdia("N/D");
                    setnumerodemediciones("N/D");
                    setpromediodemediciones("N/D");
                    setHipos([0, 0, 0, 0]);
                    setHiper([0, 0, 0, 0]);
                }

            });
    }, [fecha, bloqueTiempo, limits]);

    function obtenerLimites(limites) {
        let resultado
        if (limites) {
            let cadena = limites.toString().slice(1, -1).split(',').map(function (item) {
                return parseInt(item)
            });
            resultado = [cadena[0], cadena[1], cadena[2], cadena[3]]
        } else {
            resultado = [50, 70, 180, 250];
        }

        return resultado;

    }
    useEffect(() => {
        axios.get("https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patient/stats",
            {
                params: {
                    patient_id: id,
                },
                headers: { Authorization: IdToken }
            }).then(response => {
                setProfile(response["data"]["Items"]);
                if (response["data"]["Items"].limits.length > 0) {
                    setLimits(obtenerLimites(response["data"]["Items"].limits));
                }

            });
    }, []);

    useEffect(() => {
        axios.get("https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patient/hba1c",
            {
                params: {
                    patient_id: id,
                },
                headers: {
                    Authorization: IdToken
                }
            }).then(response => {
                console.log(response.data['Item']['hba1c']);
                // console.log(response.data['Item']['sk'].split('#')[2]);
                // if(lastHBA1C === undefined){
                if (Object.keys(response.data['Item']).length === 0) {
                    setLastHBA1C("No Data");
                    setLastHBA1CDate("No Data");
                } else {
                    setLastHBA1C(response.data['Item']['hba1c']);
                    let fecha = response.data['Item']['sk'].split('#')[2].split("-");
                    setLastHBA1CDate(`${fecha[2]}/${fecha[1]}/${fecha[0]}`);
                }
            }).catch((error) => { console.log(error) });
    });


    const handleChangeBloqueTiempo = (event) => {
        setbloqueTiempo(event.target.value);
    };

    function backOne(event) {
        setFecha(moment(fecha).add(-bloqueTiempo, 'days').toDate());
        // setFinishFecha(moment(fecha).add(bloqueTiempo, 'days').toDate())
    }
    function nextOne(event) {
        setFecha(moment(fecha).add(bloqueTiempo, 'days').toDate());
        // setFinishFecha(moment(fecha).add(bloqueTiempo, 'days').toDate())
    }
    return <div className="MainWindow" sx={{ bgcolor: "#F5F5F5" }}>
        <NavBar />
        <Grid container sx={{ bgcolor: "#FFF" }} pb={1}>
            <Grid item container xs={12} md={2}>
                <Grid container p={1} sx={{ border: "1px solid", borderRight: "0", borderColor: "rgba(0, 0, 0, 0.12)" }}>
                    <Grid pt={1} item xs={12}><Typography sx={{ display: "inline" }} variant="h6" fontSize={16} color={"#1976d2"}>{profile?.firstName ? profile?.firstName + " " + profile?.lastName : <Skeleton variant="text" sx={{ fontSize: '16' }} />}</Typography></Grid>
                    <Grid pt={1} item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="caption" sx={{ color: "#AAA" }}>DNI: {id}</Typography>
                        <PatientEmails id={profile?.id}></PatientEmails>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md>
                <Grid pt={1} pb={1} sx={{ height: "100%", border: "1px solid", borderColor: "rgba(0, 0, 0, 0.12)" }} container>
                    <DetailsBox name="FECHA DE NACIMIENTO" data={profile?.birthdate?.replaceAll("-", "/")} />
                    <Divider orientation="vertical" variant="middle" flexItem></Divider>
                    <DetailsBox name="TIPO DE DIABETES" data={profile?.diabetes_type?.toUpperCase()} />
                    <Divider orientation="vertical" variant="middle" flexItem></Divider>
                    <DetailsBox name="DIAGNÓSTICO" data={profile?.diagnosis?.toUpperCase()} />
                    {/* <Divider orientation="vertical" variant="middle" flexItem></Divider> */}
                    {/* <DetailsBox name="DISPOSITIVO DEL PACIENTE" data={data[3]} /> */}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={1} sx={{ border: "1px solid", borderLeft: "none", borderColor: "rgba(0, 0, 0, 0.12)" }}>
                <DetailsBox name="BLOQUE DE TIEMPO" data={
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={bloqueTiempo}
                            // label="Age"
                            onChange={handleChangeBloqueTiempo}
                        >
                            <MenuItem value={7}>7 días</MenuItem>
                            <MenuItem value={14}>14 días</MenuItem>
                            <MenuItem value={30}>30 días</MenuItem>
                        </Select>
                    </FormControl>
                }></DetailsBox>
            </Grid>
            <Grid item xs={12} sm={6} md={2} alignItems="center" sx={{ border: "1px solid", borderLeft: "none", borderColor: "rgba(0, 0, 0, 0.12)" }}>
                <Grid container item xs height={"100%"} direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={"auto"} p={1} onClick={backOne}>
                        <KeyboardDoubleArrowLeftIcon sx={{ border: "1px solid", borderColor: "rgba(0, 0, 0, 0.12)" }} />
                    </Grid>
                    <Grid item xs>
                        <Calendar bloqueTiempo={bloqueTiempo} value={fecha} setValue={setFecha}></Calendar>
                    </Grid>
                    <Grid item xs={"auto"} p={1} onClick={nextOne}>
                        <KeyboardDoubleArrowRightIcon sx={{ border: "1px solid", borderColor: "rgba(0, 0, 0, 0.12)" }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {/* <div className="MainContent"> */}
        <Grid container p={1} sx={{ bgcolor: "#F5F5F5" }} spacing={1}>
            {/* <Grid item container spacing={2} xs={2} className="panelizq" direction="column" justifyContent="flex-start" alignItems="stretch" pr={1} pt={1}> */}
            <Grid item xs={12} md={2}>
                <Grid item container spacing={1}>
                    {/* <Grid container xs={12}> */}
                    <Grid item xs={12} sm={6} md={12}>
                        <Paper container p={1}>
                            <Grid item container xs>
                                <Grid item container xs={12} direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                    <Grid item xs p={1}><Typography ><strong>Hba1c</strong></Typography></Grid>
                                    {/* <Button  variant="contained">Actualizar</Button> */}
                                    <Grid item xs="auto" ><ActualizarHba1c id={id} setLastHBA1C={setLastHBA1C} setLastHBA1CDate={setLastHBA1CDate}></ActualizarHba1c></Grid>
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            <Grid item container xs p={1}>
                                <Grid item xs><Typography >Valor:</Typography></Grid>
                                <Grid item xs={"auto"}><Typography >{lastHBA1C ? (lastHBA1C === "No Data" ? lastHBA1C : `${lastHBA1C}%`) : <Skeleton></Skeleton>}</Typography></Grid>
                            </Grid>
                            <Grid item container xs p={1}>
                                <Grid item xs><Typography >Fecha:</Typography></Grid>
                                <Grid item xs={"auto"}><Typography >{lastHBA1CDate ? lastHBA1CDate : <Skeleton></Skeleton>}</Typography></Grid>
                            </Grid>
                            <Grid sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                <DetalleHba1c id={id} IdToken={IdToken}>  </DetalleHba1c>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={12}>
                        <Paper container p={1}>
                            <Grid item container xs={12} direction="row"
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item xs p={1}><Typography><strong>Niveles de glicemia</strong></Typography></Grid>
                                <Grid item xs="auto" p={1}><SliderRangosGlucosa limits={limits} id={id} IdToken={IdToken} setLimits={setLimits}></SliderRangosGlucosa></Grid>
                            </Grid>
                            <Divider></Divider>
                            <Semaforo item xs={12} sx={{ px: "auto", width: 200 }} porcentajes={distr} limits={limits} />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>



            {/* </Grid> */}
            <Grid item xs={12} md={10} container spacing={1}>
                <Grid item xs={12} p={0} pt={0}>
                    <PanelItem>
                        <Grafico startDate={fecha} bloqueTiempo={bloqueTiempo} data={data} limits={limits} ></Grafico>
                    </PanelItem>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} md><PanelItem><Estadistica medicionesxdia={medicionesxdia} numerodemediciones={numerodemediciones} promedioglucemia={promediodemediciones} /></PanelItem></Grid>
                    <Grid item xs={12} md><PanelItem><Hipoglucemia hipos={hipos} /></PanelItem></Grid>
                    <Grid item xs={12} md><PanelItem><Hiperglucemia hiper={hiper} /></PanelItem></Grid>
                    {/* <Grid item xs><PanelItem><Paper p={1} height={"100%"}><Estado /></Paper></PanelItem></Grid> */}
                </Grid>
            </Grid>


        </Grid>
        {/* </div> */}
    </div>;
}

export default PatientDetailsPage;