import { useEffect, useState , useContext} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import NoiseControlOffIcon from '@mui/icons-material/NoiseControlOff';
import PatientTableIndex from './PatientTableIndex';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {
  Link
} from "react-router-dom";
import { Context } from "./hooks/context";
import axios from 'axios';
import moment from 'moment';

const columns = [
  { id: 'infopac', label: 'INFORMACIÓN DEL PACIENTE' },
  { id: 'hba1c', label: 'ÚLTIMO REGISTRO HBA1C' },
  { id: 'sinc', label: 'SINC. DISPOSITIVO' },
  // { id: 'med', label: 'med HBA1C' },
  { id: 'dist', label: 'DISTRIBUCIÓN DEL NIVEL DE GLICEMIA' },
  { id: 'hipos', label: 'TOTAL DE HIPOS E HIPERS' },
];

function createData(key, nombre, apellidos, fecha, id, hba1c, sinc, med, dist, hipos, limites) {
  let infopac = <PatientTableIndex name={nombre} lastname={apellidos} fecha={fecha} id={id} />;
  let limits = JSON.parse(limites);
  return { key, infopac, hba1c, sinc, med, dist, hipos , limits};
}

// let test = createData(<PatientTableIndex></PatientTableIndex>, 'IN', 1324171354, 3287263);

function Cell(props) {
  let left = props.left ? props.left : "none";
  let right = props.right ? props.right : "none";
  return <Box sx={{ m:0.2, p: 1 , bgcolor: 'background.paper',  borderWidth: "1px", borderColor: 'grey.500', border: 0,  borderRadius: 1 }} >{props.children}</Box>
}



function Name(props) {
  return <Cell left="1 px" right="1 px">
    <Grid container justifyContent="center" alignItems="center" color="#1976d2">
      <Grid item width={'100%'}>
      <Typography sx={{textTransform: 'capitalize'}}>{props.name}</Typography>
      </Grid>
    </Grid>
  </Cell>;
}

function LastHBA1C(props) {
  console.log(props);
  let fecha = moment(props.hba1c[1]);
  let months = ["ene.", "feb.", "mar", "abr.", "may.", "jun.", "jul.", "ago.", "sep.", "oct.", "nov.", "dic."];
  return <Cell right="1 px" height='100%'>
      <Grid container>
        <Grid container justifyContent="center" alignItems="center" xs={12} item>
          <Avatar sx={{ backgroundColor: "transparent", color: "#686767", borderStyle: "solid", borderWidth: "1px", borderColor: "#1976d2" , p:0.3}}>{props.hba1c[0] ?  props.hba1c[0]+'%' : "--"}</Avatar>
          {/* <Avatar></Avatar> */}
        </Grid>
        <Divider sx={{ m:1 }}></Divider>
        <Grid container justifyContent="center" alignItems="center" xs={12} item >
          <Typography color={'#686767'}>{props.hba1c[1]? + moment(props.hba1c[1]).date() +' ' + months[moment(props.hba1c[1]).month()] + ' ' + moment(props.hba1c[1]).year(): "-"}</Typography>
          {/* <Typography>{fecha.date()} {months[fecha.month()]}</Typography> */}
          {/* <Typography>2022</Typography> */}
        </Grid>
        {/* <Grid container justifyContent="center" alignItems="center" xs={12} item > */}
          {/* <Typography>31 Mar</Typography> */}
          {/* <Typography>{fecha.year()}</Typography>
        </Grid> */}
    </Grid>
  </Cell>;
}



function MedDia(props) {
  return <Cell right="1 px"><Grid container>
    <Grid container justifyContent="center" alignItems="center" xs={12}>
      <Avatar sx={{ backgroundColor: "transparent", color: "#ABA9AA", borderStyle: "solid", borderWidth: "1px", borderColor: "#1976d2" }}>{props.med ? props.med : "--"}</Avatar>
    </Grid>
    <Grid container justifyContent="center" alignItems="center" xs={12}>
      <Typography>{props.med ? "DEL  6" : "SIN"}</Typography>
      {/* <Typography>2022</Typography> */}
    </Grid>
    <Grid container justifyContent="center" alignItems="center" xs={12}>
      {/* <Typography>31 Mar</Typography> */}
      <Typography>{props.med ? "TOTAL" : "DATOS"}</Typography>
    </Grid>
  </Grid></Cell>;
}

function Sinc(props) {
  return <Cell right="1 px"><Grid height={"100%"} container justifyContent="center" alignItems="center">
    <Grid container xs={12} item>
      <Grid container justifyContent="right" alignItems="center" xs={6} item>
        <Grid item><Typography sx={{color: '#686767'}}><strong>{props.sinc}</strong></Typography></Grid>
      </Grid>
      <Grid item xs={6}><SmartphoneIcon /></Grid>
    </Grid>
    <Divider sx={{ width: '80%'}}></Divider>
    <Grid container justifyContent="center" alignItems="center" xs={12} item>
      <Typography sx={{ color: '#686767', mt:2}}>{props.hour}</Typography>
      {/* <Typography>2022</Typography> */}
    </Grid>
  </Grid></Cell>;
}

function Distribution(props) {
  let dist = props.distribution ? props.distribution : [0, 0, 0, 0, 0];
  let colors = ["red", "orange", "green", "#1976d2", "#1976d2"];
  let names = ["MUY BAJO", "BAJO", "IDEAL", "ALTO", "MUY ALTO"];
  let ranges = [`< ${props.limits[0]}`, `< ${props.limits[1]}`, `${props.limits[1]}-${props.limits[2]}`, `> ${props.limits[2]}`, `> ${props.limits[3]}`];

  return <Cell pt={0} right="1 px">
    <Grid container spacing={3} pt={0} justifyContent={'space-around'} >
       {/* <Box display={{ xs: "block", lg: "none" }}> 
      <Grid container display={{ xs: "block", lg: "none" }}>
        {[0, 1, 2, 3, 4].map((number) => (<Grid item><NoiseControlOffIcon />{dist[number]}</Grid>))}
      </Grid>

      </Box>  */}
      
      {[0, 1, 2, 3, 4].map((number) => (
        <Grid item xs={number===3?"auto":""} display={{ lg: "block" }}>
          {/* <Box> */}
          <Grid container justifyContent="center" color={colors[number]} alignItems="center" xs={12} item>
            <NoiseControlOffIcon />
            <Avatar sx={{ backgroundColor: "transparent", color: "#686767" }}>{dist[number]?dist[number]:"--"}</Avatar>
            {/* <Avatar></Avatar> */}
          </Grid>
          
          <Grid container justifyContent="center" alignItems="center" xs={12} item>
            <Typography fontSize={'12px'}><strong>{names[number]}</strong></Typography>
            {/* <Typography>2022</Typography> */}
          </Grid>
          <Grid container justifyContent="center" alignItems="center" xs={12} textAlign="center" item>
            {/* <Typography>31 Mar</Typography> */}
            <Typography sx={{color: "#686767" , mt:1}}>{ranges[number]} mg/dL</Typography>
          </Grid>
          {/* </Box> */}
        </Grid>
      ))}
     
      {/* <Grid item xs></Grid> */}
    </Grid>
  </Cell>;
}

function Hipos(props) {
  return <Cell right="1 px">
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Avatar sx={{ backgroundColor: "transparent", color: "#686767", borderStyle: "solid", borderWidth: "1px", borderColor: "#1976d2" , p:0.3, mb:1}}>{props.hipo ? props.hipo : "--"}</Avatar>
          {/* <Avatar></Avatar> */}
        </Grid>
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Typography pt={1} color={"#686767"}>&lt; 70 mg/dL</Typography>
          {/* <Typography>2022</Typography> */}
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Avatar sx={{ backgroundColor: "transparent", color: "#686767", borderStyle: "solid", borderWidth: "1px", borderColor: "#1976d2" , p:0.3, mb:1}}>{props.hiper ? props.hiper : "--"}</Avatar>
          {/* <Avatar></Avatar> */}
        </Grid>
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Typography pt={1} color={"#686767"}>&gt; 180 mg/dL</Typography>
        </Grid>
      </Grid>
    </Grid>
  </Cell>;
}

Date.prototype.yyyymmdd = function() {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();

  return [this.getFullYear(),
          (mm>9 ? '' : '0') + mm,
          (dd>9 ? '' : '0') + dd
         ].join('-');
};

export default function PatientTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [patients, setPatients] = useState([]);
  // const [filter, setFilter] = useState("Lastname");

  const {
      IdToken,
      handleLogout,
    } = useContext(Context);



    useEffect(()=>{
      let params=[];
      let baseURL="https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patients?";
      // 'https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/patients?indexName=HIPO&gender=mujer&begin_date=1980&end_date=2000&diagnosis=diabetes&daily_filter=1&hba1c=2&scan_index_forward=False',
      if(props?.index_name=="Profile" && !props?.scan_index_forward){
        params.push("index_name=".concat(props.index_name));
        params.push("scan_index_forward=".concat("True"));
      }else if(props?.index_name){params.push("index_name=".concat(props.index_name));}
      if(props?.gender){params.push("gender=".concat(props.gender));}//
      if(props?.begin_date){params.push("begin_date=".concat(props.begin_date));}//
      if(props?.end_date){params.push("end_date=".concat(props.end_date));}//
      if(props?.diabetes){params.push("diabetes_type=".concat(props.diabetes));}//
      if(props?.diagnosis){params.push("diagnosis=".concat(props.diagnosis));}//
      if(props?.daily_filter){params.push("daily_filter=".concat(props.daily_filter));}
      if(props?.hba1c){params.push("hba1c=".concat(props.hba1c));}
      if(props?.scan_index_forward){params.push("scan_index_forward=".concat(props.scan_index_forward));}
      let paramsUrl = baseURL.concat(params.join("&"));
      axios.get(paramsUrl, 
        {
            params:params,
            headers:{Authorization: IdToken}
        }).then(response => {
            console.log(response);
            
            let aux = Object.keys(response.data["Items"]).map((key,index)=>{
              let patient = response.data["Items"][index];
              // let time = patient.get("last_Upload", undefined);
              // let t = moment(patient["last_Upload"]);
              
              let t = undefined;
              if (patient){
                t = moment(patient["last_Upload"]);
              }
              let months = ["ene.", "feb.", "mar", "abr.", "may.", "jun.", "jul.", "ago.", "sep.", "oct.", "nov.", "dic."];
              patient["index"] = index;
              return createData(index,
                patient["firstName"],
                patient["lastName"],
                patient["birthdate"],
                patient["id"],
                [patient['hba1c'],
                patient['lastHba1cUpdate']],
                [patient["count"],"last_Upload" in patient? (t.date() +' ' + months[t.month()] + ' ' + t.year()): "No sync"],
                [4],
                [patient["BS_MB"], patient["BS_B"], patient["BS_N"], patient["BS_A"], patient["BS_MA"]],
                [patient["BS_HIPO"], patient["BS_HIPER"]],
                patient["limits"] ?? '[54,70,180,250]'
                );
            });
            setPatients(aux);
            console.log(aux);
            // setPatients();
          }).catch(function (error) {
            // Force login
            handleLogout();

            
          });
    },[props.index_name,props.gender, props.diabetes, props.begin_date, props.end_date, props.hba1c, props.daily_filter, props.scan_index_forward]);


  // const rows = [
  //   createData(0, "Jose asodkasdo", 6.4, 57, 2, [0, 2, 3, 5, 5, 0], [0, 1]),
  //   createData(1, <PatientTableIndex></PatientTableIndex>, 6.4, 57, null, [0, 0, 0, 0, 0, 0], [0, 1]),
  //   createData(2, <PatientTableIndex></PatientTableIndex>, 6.4, 57, 2, [0, 0, 0, 0, 0, 0], [0, 1]),
  //   createData(3, <PatientTableIndex></PatientTableIndex>, 6.4, 57, 2, [0, 0, 0, 0, 0, 0], [0, 1])
  // ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', mt: 1}}>
      <Grid item xs sx={{bgcolor: '#45B39D'}}>
                <Typography style={{textAlign:'center', color:'white', fontSize:15}}> DATOS OBTENIDOS EN LOS ÚLTIMOS <strong>14 DÍAS</strong></Typography>
                {/* <Item>variable width content</Item> */}
        </Grid>
      <TableContainer sx={{  bgcolor: "rgb(255 255 255)" }}>
        
        <Table aria-label="sticky table">
          
          <TableHead sx={{bgcolor: '#1976d2'}}>
            <TableRow >
              {columns.map((column) => (
                <TableCell 
                  key={column.id}
                  align={column.align}
                  style={{  color: '#F8F9F9', justifyContent:"center", textAlign: "center"}}
                 >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {patients.map((row) => {
              // console.log(patients)
              return (
                <TableRow  role="checkbox" key={row.key}>
                   <TableCell padding='none'><Link color="inherit" style={{textDecoration: 'none' }}  to={"/patient/"+row.infopac.props.id } datos={row}><Name name={row.infopac}></Name></Link></TableCell>
                  <TableCell padding='none' ><LastHBA1C hba1c={row.hba1c} /></TableCell>
                   <TableCell padding='none'><Sinc sinc={row.sinc[0]} hour={row.sinc[1]} /></TableCell> 
                  {/*<TableCell padding='none'><MedDia med={row.med}></MedDia></TableCell>  */}
                  <TableCell padding='none'><Distribution distribution={row.dist} limits={row.limits}/></TableCell>
                  <TableCell padding='none'><Hipos hipo={row.hipos[0]} hiper={row.hipos[1]} /></TableCell> 
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Box>
  );
}
