import { Typography, Divider } from "@mui/material";
export default function PatientTableIndex(props) {
    return (
        <div>
            <Typography >{props.lastname}, {props.name} </Typography>
            <Divider sx={{ width: '100%', m:0.5 }}></Divider>
            <Typography>ID: {props.id} </Typography>
            <Typography>{props.fecha} </Typography>
            
        </div>
    );
}