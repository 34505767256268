// import * as React from 'react';
// import Box from '@mui/material/Box';
// import { FormControl, InputLabel, MenuItem, Popover, Select, Typography } from '@mui/material';
// import "./styles/FiltroEdad.css"

// export default function TransitionsPopper() {
//     const [anchorEl, setAnchorEl] = React.useState(null);

//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
    
//     const handleClose = () => {
//         setAnchorEl(null);
//       };

//     let Options=["Tipo 1", "Tipo 2","Gestacional","Todos"]
//     const [diabetes, setdiabetes] = React.useState(Options[3]);

//     const handleChange = (event, newValue, activeThumb) => {
//       setdiabetes(newValue.props.value);
//         // return "";
//       };
    
//     const open = Boolean(anchorEl);
//     const id = open ? 'simple-popover' : undefined;
    
//     return (
//         <div>

//             <Typography variant="body1" display="inline" sx={{color:"#ABA9AA"}}>Tipo de diabetes:</Typography>
//             <Typography className="filterbutton" variant="body1" sx={{color:"#1976d2"}} display="inline" aria-describedby={id} onClick={handleClick}>
//               {diabetes}
//             </Typography>
            
//             <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
//                         <Box sx={{ 
//                           border: 1,
//                           p:4,
//                           width:"300px",
//                           height:"3em",
//                           bgcolor: 'background.paper'}}>
                          
//                           <FormControl fullWidth>
//                             <InputLabel id="demo-simple-select-label">diabetes</InputLabel>
//                             <Select
//                               labelId="demo-simple-select-label"
//                               id="demo-simple-select"
//                               value={diabetes}
//                               label="Diabetes"
//                               onChange={handleChange}
//                             >
//                               <MenuItem value={Options[0]}>{Options[0]}</MenuItem>
//                               <MenuItem value={Options[1]}>{Options[1]}</MenuItem>
//                               <MenuItem value={Options[2]}>{Options[2]}</MenuItem>
//                               <MenuItem value={Options[3]}>{Options[3]}</MenuItem>
//                             </Select>
//                           </FormControl>
//                         </Box>
//                     {/* </Fade> */}
//                 {/* )} */}
//             </Popover>
//         </div>
//     );
// }

import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, InputLabel, MenuItem, Popover, Select, Typography } from '@mui/material';
import "./styles/FiltroEdad.css"

export default function TransitionsPopper(props) {
    // const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setOpen((previousOpen) => !previousOpen);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    let Options=["Tipo 1", "Tipo 2","Gestacional","Todos"]
    // const [gender, setgender] = React.useState(genderOptions[2]);

    const handleChange = (event, newValue, activeThumb) => {
      if (newValue.props.value===""){
        props.setdiabetes(undefined);  
      }else{
        props.setdiabetes(newValue.props.value);
      }
      handleClose();
      
        // return "";
      };

    function displayOption(value){
      if (value==="" || value===undefined){
        return "Todos"
      }
      if (value==="tipo 1"){
        return "Tipo 1"
      }
      if (value==="tipo 2"){
        return "Tipo 2"
      }
      if (value==="gestacional"){
        return "Gestacional"
      }
    }
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <div>
            {/* <span classname="filterbutton">Test</span> */}

            <Typography variant="body1" display="inline" sx={{color:"#ABA9AA"}}>Tipo de diabetes: </Typography>
            <Typography className="filterbutton" variant="body1" sx={{color:"#1976d2"}} display="inline" aria-describedby={id} onClick={handleClick}>
              {displayOption(props?.diabetes)}
            </Typography>
            
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                {/* {({ TransitionProps }) => ( */}
                    {/* <Fade {...TransitionProps} timeout={350}> */}
                        <Box sx={{ border: 1, p:4,width:"300px", height:"3em",bgcolor: 'background.paper'}}>
                          
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Diabetes</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={props.diabetes}
                              label="Diabetes"
                              defaultValue={""}
                              onChange={handleChange}
                            >
                              <MenuItem value={"tipo 1"}>{Options[0]}</MenuItem>
                              <MenuItem value={"tipo 2"}>{Options[1]}</MenuItem>
                              <MenuItem value={"gestacional"}>{Options[2]}</MenuItem>
                              <MenuItem value={""}>{Options[3]}</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                    {/* </Fade> */}
                {/* )} */}
            </Popover>
        </div>
    );
}