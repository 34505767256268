import React, { useState, useEffect, createContext } from "react";

const Context = createContext();

function ContextProvider({ children }) {
  const [AccessToken, setAccessToken] = useState(
    JSON.parse(localStorage.getItem('AccessToken')) || null
  );
  const [IdToken, setIdToken] = useState(
    JSON.parse(localStorage.getItem('IdToken')) || null
  );
  const [User, setUser] = useState(
    JSON.parse(localStorage.getItem('User')) || null
  );
  // const [isAuth, setIsAuth] = useState(false);
  // const [token, setToken] = useState("");
  // const [idToken, setIdToken] = useState("");
  // const [tokenSchedule, setTokenSchedule] = useState("");
  // const [tokenTaonet, setTokenTaonet] = useState("");
  // const [tokenFonendo, setTokenFonendo] = useState("");
  // const [listPatients, setListPatients] = useState([]);
  // const [listPatientsFonendo, setListPatientsFonendo] = useState([]);
  // const [user, setUser] = useState("");
  // const [rutUser, setRutUser] = useState("");
  // const [anticoagulanteDefault, setAnticoagulanteDefault] =
  //   useState("Acenocumarol");
  // const [textResponse, setTextResponse] = useState([]);
  // const [interactions, setInteractions] = useState([]);
  // const [interactionsAnticoagulant, setInteractionsAnticoagulant] = useState(
  //   []
  // );
  // const [valueDate, setValueDate] = useState(new Date());
  // const [diagnosis, setDiagnosis] = useState("");
  // const [therapeuticLowerRange, setTherapeuticLowerRange] = useState();
  // const [therapeuticUpperRange, setTherapeuticUpperRange] = useState();
  // const handleLogin = (tkn, id, username, rut) => {
  //   setToken(tkn);
  //   setIdToken(id);
  //   setIsAuth(true);
  //   setUser(username);
  //   setRutUser(rut);
  // };

  useEffect(() => {
    // let data = JSON.parse(localStorage.getItem("VoyagerTaco"));
    // if (data) {
    //   setIsAuth(data.isAuth);
    //   setToken(data.token);
    //   setIdToken(data.idToken);
    //   setUser(data.user);
    //   setRutUser(data.rutUser);
    //   setTokenSchedule(data.tokenSchedule);
    //   setTokenTaonet(data.tokenTaonet);
    //   // setListPatients(data.listPatients);
    //   setAnticoagulanteDefault(data.anticoagulanteDefault);
    // }
  }, []);

  useEffect(()=>{
    localStorage.setItem('AccessToken', JSON.stringify(AccessToken));
  },[AccessToken]);

  useEffect(() => {
    var tokens = IdToken?.split(".");
    if (tokens === undefined){
      
    }else{
      console.log(JSON.parse(window.atob(tokens[0])));
      setUser(JSON.parse(window.atob(tokens[1])));
      localStorage.setItem('IdToken', JSON.stringify(IdToken));
      localStorage.setItem('User', JSON.stringify(JSON.parse(window.atob(tokens[1]))));
      // console.log(JSON.parse(window.atob(tokens[2])));
    }
  }, [
    IdToken
  ]);

  const handleLogout = () => {
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("IdToken");
    localStorage.removeItem("User");
    setAccessToken(null);
    setIdToken(null);
    setUser(null);
  };


  return (
    <Context.Provider
      value={{
        AccessToken,
        setAccessToken,
        IdToken,
        setIdToken,
        User,
        setUser,
        // setIsAuth,
        // isAuth,
        // handleLogin,
        handleLogout,
        // idToken,
        // tokenSchedule,
        // setTokenSchedule,
        // tokenFonendo,
        // setTokenFonendo,
        // tokenTaonet,
        // setTokenTaonet,
        // setListPatients,
        // listPatients,
        // getPatient,
        // user,
        // setUser,
        // rutUser,
        // setRutUser,
        // anticoagulanteDefault,
        // setAnticoagulanteDefault,
        // textResponse,
        // setTextResponse,
        // interactions,
        // setInteractions,
        // interactionsAnticoagulant,
        // setInteractionsAnticoagulant,
        // valueDate,
        // setValueDate,
        // diagnosis,
        // setDiagnosis,
        // therapeuticLowerRange,
        // setTherapeuticLowerRange,
        // therapeuticUpperRange,
        // setTherapeuticUpperRange,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { ContextProvider, Context };
