import * as React from 'react';
import Box from '@mui/material/Box';
import { Popover, Slider, Typography } from '@mui/material';
import "./styles/FiltroEdad.css"

export default function TransitionsPopper(props) {
    // const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        // setOpen((previousOpen) => !previousOpen);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    // const canBeOpen = open && Boolean(anchorEl);
    // const id = canBeOpen ? 'transition-popper' : undefined;

    function valuetext(value) {
        return `${value}°C`;
      }

    
    const minDistance = 1;
    const [value1, setValue1] = React.useState([0, 150]);

    function subtractYears(date, years) {
      date.setFullYear(date.getFullYear() - years);
      return date;
    }

    const handleChange2 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
          return;
        }
    
        if (newValue[1] - newValue[0] < minDistance) {
          if (activeThumb === 0) {
            const clamped = Math.min(newValue[0], 150 - minDistance);
            setValue1([clamped, clamped + minDistance]);
          } else {
            const clamped = Math.max(newValue[1], minDistance);
            setValue1([clamped - minDistance, clamped]);
          }
        } else {
          setValue1(newValue);
        }
        // let now = new Date();
        props.setbegin_date(subtractYears(new Date(), value1[1]).toISOString());
        // props.begin_date={begin_date}
        props.setend_date(subtractYears(new Date(), value1[0]).toISOString());
        // end_date={end_date}
      };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    return (
        <div>
            {/* <span classname="filterbutton">Test</span> */}

            <Typography variant="body1" display="inline" sx={{color:"#ABA9AA"}}>Edad:</Typography>
            <Typography className="filterbutton" variant="body1" sx={{color:"#1976d2"}} display="inline" aria-describedby={id} onClick={handleClick}>
              {value1[0]===0 & value1[1]===150
              ?"Todos"
              :<span>Entre {value1[0]} y {value1[1]}</span>}
            </Typography>
            
            <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
                {/* {({ TransitionProps }) => ( */}
                    {/* <Fade {...TransitionProps} timeout={350}> */}
                        <Box sx={{ border: 1, p:4,width:"300px", height:"3em",bgcolor: 'background.paper'}}>
                          Entre <strong>{value1[0]}</strong> y <strong>{value1[1]}</strong>
                            <Slider
                                getAriaLabel={() => 'Minimum distance shift'}
                                value={value1}
                                onChange={handleChange2}
                                valueLabelDisplay="auto"
                                min={0}
                                // marks
                                max={150}
                                getAriaValueText={valuetext}
                                disableSwap
                            />
                        </Box>
                    {/* </Fade> */}
                {/* )} */}
            </Popover>
        </div>
    );
}