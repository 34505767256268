// import NavBar from "./NavBar.js";

import Person from "@mui/icons-material/Person";
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, Typography } from "@mui/material";
import FiltroEdad from "./FiltroEdad.js";
import FiltroSexo from "./FiltroSexo.js";
import FiltroHba1c from "./FiltroHba1c.js";
import FiltroDiabetes from "./FiltroDiabetes.js";
import FiltroMediciones from "./FiltroMediciones.js";
import "./styles/TopBar.css"
import React from "react";
import Formulario from './Formulario.js'
import FormularioPaciente from './FormularioPaciente.js'
import { Context } from "./hooks/context";
import {useContext} from "react";
import jwt_decode from "jwt-decode";
import SortToggleButtons from "./components/SortToggleButtons.js";

function TopBar(props) {
    const {
        // AccessToken,
        IdToken,
    } = useContext(Context);

var decoded = jwt_decode(IdToken);
console.log(decoded["cognito:groups"].includes('admin'));

var admin = decoded["cognito:groups"].includes('admin');



    let nresultados = 203;
    const [pacientesporpagina, setpacientesporpagina] = React.useState(20);
    
    const handleChangeppp = (event, newValue, activeThumb) => {
        setpacientesporpagina(newValue.props.value);
    }

    const handleChangeorden = (event, newValue, activeThumb) => {
        props.setindex_name(newValue.props.value);
        console.log(props.index_name);
    }

    return <Grid >
        <Grid container spacing={3} >
        </Grid>
        <Grid container justifyContent="flex-start" spacing={2} mt={2}>
            <Grid item md="auto" xs={12}>
                <Box sx={{ border:0, borderRadius:1, bgcolor: "white", width: "fit-content", p: 1,  borderColor: 'grey.400'  }}>
                    <Grid container spacing={3} >
                        <Grid item xs>
                            <Grid container>
                                <Grid item color={'#1976d2'}>
                                    <strong>Filtros aplicados:</strong>
                                </Grid>
                                <Grid item sx={{ pl: 1 }}>
                                    <FiltroEdad 
                                        setbegin_date={props.setbegin_date}
                                        begin_date={props.begin_date}
                                        setend_date={props.setend_date}
                                        end_date={props.end_date}>
                                    </FiltroEdad>
                                </Grid>
                                <Divider sx={{ pl: 1 }} orientation="vertical" variant="" flexItem></Divider>
                                <Grid item sx={{ pl: 1 }}><FiltroSexo gender={props.gender} setgender={props.setgender}></FiltroSexo></Grid>
                                <Divider sx={{ pl: 1 }} orientation="vertical" variant="" flexItem></Divider>
                                <Grid item sx={{ pl: 1 }}><FiltroDiabetes diabetes={props.diabetes} setdiabetes={props.setdiabetes}></FiltroDiabetes></Grid>
                                <Divider sx={{ pl: 1 }} orientation="vertical" variant="" flexItem></Divider>
                                <Grid item sx={{ pl: 1 }}><FiltroHba1c hba1c={props.hba1c} sethba1c={props.sethba1c}></FiltroHba1c></Grid>
                                <Divider sx={{ pl: 1 }} orientation="vertical" variant="" flexItem></Divider>
                                <Grid item sx={{ pl: 1 }}><FiltroMediciones daily_filter={props.daily_filter} setdaily_filter={props.setdaily_filter}></FiltroMediciones></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* <Grid item xs="auto"><Button className="button-round" variant="contained" endIcon={<EditIcon />}
                sx={{
                    backgroundColor: "#FFF",
                    color: "#1976d2",
                    border: "1px solid",
                    borderRadius: "100px",
                    '&:hover': {
                        backgroundColor: "#CCC",
                    },
                }}>Filtros</Button></Grid> */}
            <Grid item xs="auto" container
                direction="row" justifyContent="center" alignItems="center"><Typography variant="body2" color="#1976d2" sx={{ textDecoration: 'underline' }}>
                    {/* Borrar todos los filtros */}
                </Typography></Grid>

                <Grid item container xs direction="row" justifyContent="flex-end" >
                {/* <Item>xs</Item> */}
                <Grid item>
                   {admin && (
                    <Formulario></Formulario>
                   )} 
                   {!admin && (
                    <FormularioPaciente></FormularioPaciente>
                   )}
                </Grid>

            </Grid>
        </Grid>

        <Grid container justifyContent="flex-start" spacing={2} mt={2}>
            {/* <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Pacientes por página</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={pacientesporpagina}
                        sx={{ bgcolor: "#FFF" }}
                        label="Pacientes por página"
                        onChange={handleChangeppp}
                    >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labelo">Ordenar por</InputLabel>
                    <Select
                        labelId="demo-simple-select-labelo"
                        id="demo-simple-select"
                        value={props.index_name}
                        defaultValue={"Profile"}
                        sx={{ bgcolor: "#FFF" }}
                        label="Ordenar por"
                        onChange={handleChangeorden}
                    >
                        <MenuItem value={"Profile"}>Apellidos</MenuItem>
                        <MenuItem value={"HIPO"}>Hiperglicemia</MenuItem>
                        <MenuItem value={"HIPER"}>Hipoglicemia</MenuItem>
                        <MenuItem value={"lastupload"}>Última glicemia registrada</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}><SortToggleButtons setscan_index_forward = {props.setscan_index_forward} scan_index_forward={props.scan_index_forward}></SortToggleButtons></Grid>
            <Grid item xs   >
                
            </Grid>
            {/* <Grid item xs="auto" container direction="row" justifyContent="center" alignItems="center">
                <Pagination count={10} variant="outlined" shape="rounded" />
            </Grid> */}
        </Grid>




    </Grid>;
}

export default TopBar;