import { Box, Button, Modal, FormControl, Divider,Grid,MenuItem, Typography } from "@mui/material";
import Person from "@mui/icons-material/Person";
import {useEffect, useState , useContext} from "react";
import { Context } from "./hooks/context";
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import moment from "moment";
import {es} from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import swal from 'sweetalert';
import InputAdornment from '@mui/material/InputAdornment';



export default function FormularioPaciente() {
    const [modal, setModal]= useState(false);

   const {register, formState:{errors}, watch, resetField, handleSubmit} = useForm();

   const [genero, setGenero] = React.useState(null);
   const [date, setDate] = React.useState(null);
   const [diabetes, setDiabetes] = React.useState(null);
   

   const {
    AccessToken,
    IdToken,
} = useContext(Context);
   


   React.useEffect(() => {
    console.log('useEffect')
    
}, [modal,  date, genero, diabetes]);


  const alertaExito = (x)=>{
    swal({
      title: 'Nuevo Usuario',
      text: `El usuario ${x} fue creado con éxito`,
      icon: 'success',
      button: 'Aceptar'
    });
  }

  const alertaError = (status, message)=>{
    swal({
      icon: 'error',
      title: 'Algo salió mal',
      text: 'Error al crear usuario'+ '\n' + message,
      button: 'OK'
    })
  }


   const handleChangeGenero = (event) => {
    setGenero(event.target.value);
   };

   const handleChangeDiabetes = (event) => {
    setDiabetes(event.target.value);
   };
 


   const generos = [
    {
      value: 'hombre',
      label: 'Hombre',
    },
    {
      value: 'mujer',
      label: 'Mujer',
    },
    {
      value: 'otro',
      label: 'Otro',
    },
    {
      value: 'desconocido',
      label: 'Desconocido',
    },
  ];

  const tiposDiabetes = [
    {
      value: 'tipo 1',
      label: 'Tipo 1',
    },
    {
      value: 'tipo 2',
      label: 'Tipo 2',
    },
    {
      value: 'gestacional',
      label: 'Gestacional',
    },
    {
      value: 'otro',
      label: 'Otro',
    },
    {
      value: 'desconocido',
      label: 'Desconocido',
    },
  ];


 

    const onSubmit = (data)=>{
        var fecha = data.birthdate.split("/").reverse().join("/");
             var data = JSON.stringify({
              "id": data.id,
              "firstName": data.firstName,
              "lastName": data.lastName,
              "email": data.email,
              "gender": genero,
              // "cellphone": '+56'+ data.cellphone,
              "birthdate": fecha,
              "profile": "PATIENT",
              "password": data.password,
              "diabetes_type": diabetes,
              "diagnosis": "diabetes",
              // "debug":"true"
            });
                
              var config = {
              method: 'post',
              url: 'https://evba1pxtwf.execute-api.us-east-1.amazonaws.com/default/user',
              headers: { 
                  'Authorization': IdToken, 
              },
              data : data
              };
          
              axios(config)
              .then(function (response) {
              alertaExito(response.data["Username"]);
              console.log(JSON.stringify(response.data));
              })
              .catch(function (error) {
              alertaError(error);
              console.log(error);
              });
              abrirCerrarModal();
    
  }

    const nuevoUsuario=()=>{
        abrirCerrarModal();
    }
    const abrirCerrarModal =()=>{
      resetField("firstName");
      resetField("lastName");
      resetField("email");
      // resetField("password");
      resetField("id");
      resetField("cellphone");
      resetField("birthdate");
      setDiabetes(null);
      setGenero(null);
      setDate(null);
      setModal(!modal);
       
    }

    const body=(
        <Grid sx={{
            height: '80vh',
            overflowY: "auto",
            position: 'absolute',
            backgroundColor: 'white',
            border: '1px solid #000',
            borderRadius: '5px',
            borderColor:'white',
            boxShadow: 5 ,
            padding: '24px 5px 24px 5px',
            top: '50%',
            left: '50%',
            width : '50%',
            transform: 'translate(-50%,-50%)'
        }}>
            <Grid item xs p={2} textAlign={'center'}>
                <Typography mb={1} color={'#1976d2'} fontSize={'25px'}> <strong>NUEVO PACIENTE</strong></Typography>
                <Divider></Divider>
            </Grid>
                <Grid item container xs p={1} textAlign={'center'} justifyContent={'center'} >
                 <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}  width={'90%'}>   
                    <div display={'flex'} flexDirection= {'row'} justifyContent={'space-between'}  >
                    {errors.firstName?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese un nombre</Typography>}
                   
                    <TextField  required label="Nombre"  variant="outlined" sx={{mt:1, p:1, width:'100%'}} {...register('firstName', {required: true})} />
                    {errors.lastName?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese un apellido</Typography>}
                    <TextField required label="Apellido" variant="outlined" sx={{mt:1, p:1, width:'100%'}} {...register('lastName', {required: true})} />
                   
                    </div>
                    <div>
                    {errors.email?.type ==='pattern' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Formato de email es incorrecto</Typography>}
                    {errors.email?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese un email</Typography>}
                    <TextField required label="Email" variant="outlined" sx={{mt:1, p:1, width:'100%'}} 
                    {...register('email', {required: true, pattern: /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}\b/ })} />
                
                    </div>
                    {/* <div>
                    {errors.password?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese una contraseña</Typography>}
                    {errors.password?.type ==='pattern' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Formato de contraseña incorrecto</Typography>}
                    <TextField required label="Contraseña" helperText="La contraseña debe tener mínimo 8 caracteres que incluyan al menos una mayúscula, una minúscula, un caracter especial y un número" variant="outlined" type="password" sx={{mt:1, p:1, width:'100%'}} {...register('password', {required: true,  pattern : /[A-Za-z0-9@#!$%^&+=]{8,}/})}/>
                   
                    </div> */}
                    <div>
                    {errors.id?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese un ID</Typography>}
                    <TextField required label="ID" variant="outlined" sx={{mt:1, p:1, width:'100%'}} {...register('id', {required: true})} type={'text'} />
                    
                    </div>
                    {/* <div>
                    {errors.cellphone?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Ingrese un número de teléfono</Typography>}
                    <TextField  required label="Teléfono" InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <p>+56</p>
                        </InputAdornment>
                      ),
                    }} 
                    type={'number'} variant="outlined" sx={{mt:1, p:1, width:'100%'}} {...register('cellphone', {required: true})} />
                   
                    </div> */}
                    <div>
                    {errors.gender?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Seleccione un género</Typography>}
                    <TextField
                    required
                        select
                        id="selectGenero"
                        label="Genero"
                        value={genero}
                        onChange={handleChangeGenero}
                        sx={{mt:1, p:1, width:'100%'}}
                        >
                        {generos.map((option) => (
                            <MenuItem key={option.value} value={option.value} {...register('gender', {required: true})} >
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                  
                    </div>
                    <div >
                    {errors.birthdate?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Seleccione una fecha de nacimiento</Typography>}
                        <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={es}>
                        <DatePicker
                        label="Fecha de nacimiento"
                        value={date}
                        onChange={(newValue) => {
                            setDate(newValue)
                        }}
                        renderInput={(params) => <TextField required {...params} sx={{mt:1, p:1, width:'100%'}} {...register('birthdate', {required: true})} />}
                        />

                    </LocalizationProvider>
                    
                    </div>
                    
                    <div>
                    {errors.diabetes_type?.type ==='required' && <Typography sx={{m:'0', width:'100%', color:'red'}} ariant="caption" display="block" gutterBottom>Seleccione un tipo de diabetes</Typography>}
                    <TextField
                        id="outlined-select-currency"
                        select
                        required 
                        label="Tipo de diabetes"
                        value={diabetes}
                        onChange={handleChangeDiabetes} 
                        sx={{mt:1, p:1, width:'100%'}}
                        >

                        {tiposDiabetes.map((option) => (
                            <MenuItem key={option.value} value={option.value}  {...register('diabetes_type', {required: true})} >
                            {option.label}
                            </MenuItem>
                        ))}
                     
                    </TextField>
                   
                    </div>
                    
                    <Grid  textAlign={'center'} justifyContent={'center'}>
                        <Button variant="contained" size="large" sx={{m:1, p:1, width: '45%'}}  onClick={()=>abrirCerrarModal()}>Cancelar</Button>
                        <Button variant="contained" size="large" sx={{m:1, p:1, width: '45%'}} type="submit">Guardar</Button>
                    </Grid>
                  
                 </Box>
                </Grid>
        </Grid>
      )

    return (
        <div>
           <Button className="button-round" variant="contained" endIcon={<Person />}sx={{borderRadius: "100px" }} onClick={()=>nuevoUsuario()}>Nuevo Paciente</Button>
           <Modal open={modal} onClose={abrirCerrarModal}>
                {body}
            </Modal>
        </div>
    );
}