import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import SortIcon from '@mui/icons-material/Sort';

export default function SortToggleButtons(props) {
  const [alignment, setAlignment] = React.useState('True');

  const handleAlignment = (event, newAlignment) => {
    props.setscan_index_forward(newAlignment);
    setAlignment(newAlignment);
  };

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="Sort ascending"
    >
      <ToggleButton value="True" aria-label="descending">
        <SortIcon />
        <KeyboardDoubleArrowDownIcon />
      </ToggleButton>
      <ToggleButton value="False" aria-label="ascending">
        <SortIcon />
        <KeyboardDoubleArrowUpIcon />
      </ToggleButton>
      
    </ToggleButtonGroup>
  );
}